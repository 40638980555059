import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, Switch, Route, Redirect } from 'react-router-dom';
// mui components
import { Grid, Tabs, Tab, Box, TabProps, Button, ButtonProps, Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import TalkToUsLink from 'components/talk-to-us-link';
// sections
import DealsSection from './sections/deals-section';
import ProfileSection from './sections/profile-section';
import SettingSection from './sections/setting-section';
import DealDetailsSection from './sections/deal-details-section';
// custom hooks
import { useUserData } from 'hooks/useUserData';
// icons
import { ReactComponent as DealIcon } from 'assets/icons/deal_2.svg';
import { ReactComponent as MusicIcon } from 'assets/icons/music.svg';
// import { ReactComponent as SettingIcon } from 'assets/icons/setting.svg';
import { ReactComponent as LogOutIcon } from 'assets/icons/log-out.svg';
// styles
import { colors } from 'styles/colors';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      display="flex"
      position="relative"
      width={value !== index ? '0' : '100%'}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
};

const StyledTabs = styled(Tabs)(({ theme }) => ({
  width: '218px',
  paddingTop: '40px',
  backgroundColor: colors.main.black,
  '& .MuiTabs-indicator': {
    backgroundColor: theme.colors.main.yellow,
    left: 0,
    right: 'unset',
  },
}));

const StyledTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  justifyContent: 'flex-start',
  minHeight: '56px',
  marginBottom: '16px',
  padding: '12px 27px',
  ...theme.fontStyles.subtext,
  color: theme.colors.text.light.placeholder,
  textTransform: 'unset',
  '&.Mui-selected': {
    color: theme.colors.main.white,
    '& path': {
      fill: theme.colors.main.white,
    },
  },
  '& .MuiTab-iconWrapper': {
    marginRight: '16px',
  },
}));

const StyledButton = styled(({ className, onClick, children }: ButtonProps) => (
  <Button
    disableRipple
    className={className}
    startIcon={<LogOutIcon />}
    onClick={onClick}
    children={children}
  />
))(({ theme }) => ({
  justifyContent: 'flex-start',
  minHeight: '56px',
  marginBottom: '16px',
  padding: '12px 27px',
  ...theme.fontStyles.subtext,
  color: theme.colors.text.light.placeholder,
  textTransform: 'unset',
  '& .MuiButton-startIcon': {
    marginRight: '16px',
  },
}));

const a11yProps = (index: number) => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`,
});

enum SectionsEnum {
  deals = 0,
  account = 1,
  setting = 2,
}

const Profile: React.FC = () => {
  const history = useHistory();

  const { logOut } = useUserData();

  const pathnameSection = useMemo(() => {
    const pathname = history.location.pathname;
    return pathname.substring(pathname.lastIndexOf('/') + 1);
  }, [history.location.pathname]);

  const currentSection = useMemo(() => {
    if (Number(pathnameSection)) {
      return 'deals';
    }

    return pathnameSection;
  }, [pathnameSection]);

  const currentTab = useMemo(() => {
    // @ts-ignore
    return SectionsEnum[currentSection];
  }, [currentSection]);

  const [value, setValue] = useState(currentTab);

  const isDealDetails = useMemo(() => !!Number(pathnameSection), [pathnameSection]);

  useEffect(() => {
    if (!currentTab && !isDealDetails) {
      history.replace({ pathname: '/profile/deals' });
      setValue(SectionsEnum.deals);
      return;
    }

    if (isDealDetails) {
      history.replace({ pathname: `/profile/deals/${pathnameSection}` });
    } else {
      history.replace({ pathname: `/profile/${pathnameSection}` });
    }

    setValue(currentTab);

    // eslint-disable-next-line
  }, [currentTab]);

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);

      const section = SectionsEnum[newValue];
      history.replace({ pathname: `/profile/${section}` });
    },
    [history]
  );

  const handleClickOnDeal = useCallback(() => {
    if (isDealDetails) {
      history.push('/profile/deals');
    }
  }, [isDealDetails, history]);

  return (
    <Grid
      container
      flexDirection="column"
      alignItems="center"
      sx={{ minHeight: 'calc(100vh - 72px)', backgroundColor: '#1A0034' }}
    >
      <Grid
        container
        wrap="nowrap"
        flexGrow={1}
        sx={{ maxWidth: '1150px', backgroundColor: colors.main.white }}
      >
        <Hidden smDown>
          <StyledTabs
            value={value}
            variant="scrollable"
            orientation="vertical"
            onChange={handleChangeTab}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <StyledTab
              label="Deals"
              icon={<DealIcon />}
              iconPosition="start"
              {...a11yProps(0)}
              onClick={handleClickOnDeal}
            />
            <StyledTab
              label="Profile"
              icon={<MusicIcon />}
              iconPosition="start"
              {...a11yProps(1)}
            />
            {/* <StyledTab
              label="Setting"
              icon={<SettingIcon />}
              iconPosition="start"
              {...a11yProps(2)}
            /> */}
            <StyledButton onClick={logOut}>Log out</StyledButton>
          </StyledTabs>
        </Hidden>

        <TabPanel value={value} index={0}>
          <Switch>
            <Route exact path="/profile/deals" component={DealsSection} />
            <Route exact path="/profile/deals/:id" component={DealDetailsSection} />
            <Redirect to="/profile/deals" />
          </Switch>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TalkToUsLink isProfile />
          <ProfileSection />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TalkToUsLink isProfile />
          <SettingSection />
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default Profile;
