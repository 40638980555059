import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// components
import Slider from 'components/slider';
// types
import { SliderProps } from '@mui/material';

interface OwnProps extends SliderProps {
  handleChange?: (value: number) => void;
  [x: string]: any;
}

const SliderController: React.FC<OwnProps> = ({
  name = '',
  defaultValue = '',
  handleChange,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { ref, onChange, ...fieldProps } }) => (
        <Slider
          {...fieldProps}
          {...props}
          onChange={onChange}
          onChangeCommitted={(_event, value) => {
            if (typeof value === 'number') {
              handleChange && handleChange(value);
            }
          }}
        />
      )}
    />
  );
};

export default memo(SliderController);
