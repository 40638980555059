import { Grid, Box } from '@mui/material';
import { colors } from 'styles/colors';

import LandingHeader from './landing-header';
import Intro from './intro';
import AboutUs from './about-us';
import EstimateSection from './estimate-section';
import FaqSection from './faq-section';
import FooterSection from './footer-section';

export default function LandingPage(props: { isAuthorized: boolean | null }) {
  const { isAuthorized } = props;
  return (
    <Grid
      container
      overflow="hidden"
      sx={{
        backgroundColor: colors.background.dark.deepBlue,
      }}
    >
      <LandingHeader isAuthorized={isAuthorized} />

      <Box
        id="intro-section"
        width="100%"
        sx={{
          scrollMarginTop: '100px',
        }}
      >
        <Intro isAuthorized={isAuthorized} />
      </Box>

      <Box id="aboutus-section" width="100%">
        <AboutUs />
      </Box>

      <Box width="100%">
        <EstimateSection isAuthorized={isAuthorized} />
      </Box>

      <Box id="faq-section" width="100%">
        <FaqSection />
      </Box>

      <Box width="100%">
        <FooterSection />
      </Box>
    </Grid>
  );
}
