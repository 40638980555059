import { useEffect, useState } from 'react';

import dealStore from './store';

import Steps from './components/Steps';
import Progress from './components/Progress';
import DealFlow from './components/DealFlow';

import { useParams } from 'react-router-dom';
import { useGetTracks } from 'hooks/swrRequests';

import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useError } from 'hooks/useError';
import ErrorModal from 'components/error-modal';
import Text from 'components/text';
import api from 'utils/api';
import { retry } from 'utils/helpers';
import SubmitButton from './components/SubmitButton';

export default function NewDeal() {
  const store = dealStore();
  const [loading, setLoading] = useState(true);
  const { message, setError } = useError();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const {
    state,
    setCurrentStepNumber,
    setDeal,
    setTracks,
    setFormData,
    toggleFullCatalogue,
    toggleTrack,
  } = store;

  const { currentStepNumber } = state;
  const { id } = useParams<{ id: string }>();
  const [dealData, setDealData] = useState<any>(null);
  const [dealError, setdealError] = useState<any>(false);

  const { data: tracksData, error: tracksError } = useGetTracks(id, dealData?.artistSpotifyId);

  useEffect(() => {
    retry(() => api.deals.getDeal({ id }), 5)
      .then((res) => {
        setDealData(res);
      })
      .catch(() => {
        setdealError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (dealData && tracksData) {
      setOpenErrorModal(false);
      setDeal(dealData);

      const { ownEstimation, isEntireCatalogue, tracks, lengthOfCommitment }: any = dealData;
      setTracks(handleTracksAddedManually(tracks, tracksData));
      setLoading(false);

      setFormData({ ownEstimation: ownEstimation || 0, commitmentLength: lengthOfCommitment || 1 });
      toggleFullCatalogue(isEntireCatalogue || false);
      if (!isEntireCatalogue) {
        tracks.map((track: any) => toggleTrack(track.trackSpotifyId));
      }
      setCurrentStepNumber(decideCurrectStepNumber(dealData));
    } else if (dealError || tracksError) {
      setError(
        'Something went wrong while estimating monthly revenue. Please refresh this page and try again.'
      );
      setOpenErrorModal(true);
    }
    // eslint-disable-next-line
  }, [dealData, dealError, tracksData, tracksError]);

  return (
    <>
      {currentStepNumber <= 4 && (
        <>
          <Progress value={currentStepNumber * 25} />
          <Steps steps={state.steps} currentStepNumber={currentStepNumber} />
        </>
      )}

      {loading ? (
        <Box
          minHeight="400px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box paddingBottom="60px">
            <CircularProgress />
          </Box>
          <Box maxWidth="500px">
            <Text fontSize="20px" paddingBottom="16px" lineHeight="28px">
              We are currently calculating your estimated monthly revenue. Analysis may take a few
              minutes!
            </Text>
          </Box>
        </Box>
      ) : dealError ? (
        <RenderDealError />
      ) : (
        <DealFlow store={store} />
      )}
      <ErrorModal
        open={openErrorModal}
        handleClose={() => setOpenErrorModal(false)}
        message={message}
      />
    </>
  );
}

function RenderDealError() {
  return (
    <Box maxWidth="500px" marginX="auto" marginTop="100px">
      <Text fontSize="26px" paddingBottom="26px" lineHeight="28px">
        Error occurred while loading deal
      </Text>
      <Text fontSize="18px" paddingBottom="0px" lineHeight="28px">
        Something went wrong while estimating monthly revenue.
      </Text>
      <Text fontSize="18px" paddingBottom="0px" lineHeight="28px">
        Please make sure:
      </Text>
      <Box component="ol" marginTop="5px">
        <Box component="li">
          <Text fontSize="18px" paddingBottom="0px" lineHeight="28px">
            You have an active internet connection.
          </Text>
        </Box>
        <Box component="li">
          <Text fontSize="18px" paddingBottom="0px" lineHeight="28px">
            Your email is verified, check inbox if not.
          </Text>
        </Box>
        <Box component="li">
          <Text fontSize="18px" paddingBottom="0px" lineHeight="28px">
            Artist has an active Spotify account and has at least three tracks on Spotify.
          </Text>
        </Box>
      </Box>

      <Box marginTop="36px">
        <SubmitButton
          type="submit"
          onClick={() => {
            window.location.reload();
          }}
        >
          <Text fontSize="20px" fontWeight="bold" color="#fff" paddingX="24px" paddingY="9px">
            Try again
          </Text>
        </SubmitButton>
      </Box>
    </Box>
  );
}

function decideCurrectStepNumber(deal: any) {
  const { ownEstimation, tracks, lengthOfCommitment, status } = deal;

  if (ownEstimation && !tracks.length) {
    return 2;
  }

  if (tracks.length > 0 && !lengthOfCommitment) {
    return 3;
  }

  if (lengthOfCommitment && status === 'in_progress') {
    return 4;
  }

  if (status === 'submitted') {
    return 5;
  }

  return 1;
}

function transformTrack(track: any) {
  return {
    id: track.trackSpotifyId,
    name: track.name,
    popularity: parseInt(track.popularity),
    album_name: track?.albumName || '',
    date_released: track.dateReleased,
    artists: track?.artists || '',
    artists_count: parseInt(track.total_artists),
    ownership: parseInt(track.ownership),
  };
}

function handleTracksAddedManually(selectedTracks = [], topTracks: any = []) {
  const allTracks: any = [...topTracks];

  if (selectedTracks.length) {
    selectedTracks.forEach((track: any) => {
      const trackExists = allTracks.find((t: any) => t.id === track.trackSpotifyId);
      if (!trackExists) {
        allTracks.push(transformTrack(track));
      }
    });
  }

  const transformedTracks = allTracks.map((track: any) => {
    const existingTrack: any = selectedTracks.find((t: any) => t.trackSpotifyId === track.id);
    return existingTrack ? transformTrack(existingTrack) : track;
  });

  return transformedTracks;
}
