import { IPayloadTrack } from 'utils/helpers';

export default function TransformTracks(tracks: any, trackSpotifyId: number): IPayloadTrack {
  return tracks
    .filter((track: any) => track.selected)
    .map((track: any) => {
      const { id, name, date_released, ownership, artists_count, popularity } = track;
      return {
        trackSpotifyId: id,
        name,
        dateReleased: date_released,
        ownership,
        total_artists: artists_count,
        popularity,
      };
    });
}
