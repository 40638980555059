import React, { useCallback, useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
// mui components
import { Grid, Typography } from '@mui/material';
// layouts
import AuthLayout from 'layouts/auth-layout';
import CompletedSignUp from './completed-sign-up';
// components
import StyledInputContainer from '../components/styled-input-container';
import StyledButton from '../components/styled-button';
import TalkToUsLink from 'components/talk-to-us-link';
import StyledLink from '../components/styled-link';
import StyledText from '../components/styled-text';
// controllers
import AutocompleteInputController from 'controllers/autocomplete-input-controller';
import PasswordInputController from 'controllers/password-input-controller';
import BaseInputController from 'controllers/base-input-controller';
// hooks
import { useError } from 'hooks/useError';
// validations
import { SignUpSchema } from './validation';
// api
import api from 'utils/api';
// styles
import { fontStyles } from 'styles/font-styles';
import { colors } from 'styles/colors';
import { GaEvents, GaEventCategory } from 'utils/gaEvents';

interface IArtistList {
  label: string;
  value: number | string;
}

interface ISpotifyData {
  items: {
    id: number;
    name: string;
  }[];
  id: number;
  name: string;
}

const SignUp: React.FC = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [hasInteracted, setHasInteracted] = useState(false);
  const [artistList, setArtistList] = useState<IArtistList[]>([]);

  const { message, setError } = useError();
  const [isSubmitting, setSubmitting] = useState(false);
  const [signUpIsCompleted, setSignUpIsCompleted] = useState(false);
  const [defaultEmail, setDefaultEmail] = useState('');
  const [defaultArtistName, setDefaultArtistName] = useState('');
  const [showArtistInput, setShowArtistInput] = useState(false);

  const form = useForm({ resolver: yupResolver(SignUpSchema) });

  const handleInputFieldInteractionEvent = useCallback(() => {
    if (!hasInteracted) {
      setHasInteracted(true);
      GaEvents[GaEventCategory.SIGNUP_PAGE].fieldInteraction();
    }
  }, [hasInteracted]);

  const searchArtist = useCallback(
    async (value: string) => {
      handleInputFieldInteractionEvent();
      const data: ISpotifyData = await api.spotify.getSpotifyArtist({ id: value });

      const formattedData =
        data.items && Array.isArray(data.items)
          ? data.items.map(({ id, name }) => ({ label: name, value: id }))
          : [{ label: data.name, value: data.id }];

      setArtistList(formattedData);
    },
    [handleInputFieldInteractionEvent]
  );

  const handleSearchArtist = debounce(searchArtist, 300);

  useEffect(() => {
    const qs: URLSearchParams = new URLSearchParams(search);
    const email: string = decodeURIComponent(qs.get('e') || '');
    const artistSpotifyId: string = decodeURIComponent(qs.get('a') || '');
    const artistName: string = decodeURIComponent(qs.get('n') || '');
    const prf: string = decodeURIComponent(qs.get('prf') || '');

    if (Number(prf) === 1) {
      const newArtist: IArtistList[] = [{ label: artistName, value: artistSpotifyId }];
      setArtistList(newArtist);

      form.setValue('email', email);
      form.setValue('artistSpotifyId', artistSpotifyId);
      setDefaultEmail(email);
      setDefaultArtistName(artistName);
      setShowArtistInput(true);
    } else {
      if (!showArtistInput) {
        setShowArtistInput(true);
      }
    }

    history.replace('/sign-up');
    // eslint-disable-next-line
  }, [defaultArtistName, defaultEmail, form, search]);

  const onSubmit = useCallback(
    async (formValues: any) => {
      try {
        setError(null);
        setSubmitting(true);
        const formData = { ...formValues, email: formValues.email.toLowerCase() };
        await api.auth.signUp({ data: formData }).then(() => {
          setSignUpIsCompleted(true);
        });
      } catch (err) {
        setError(err);
      } finally {
        setSubmitting(false);
      }
    },
    [setError]
  );

  return (
    <>
      {signUpIsCompleted ? (
        <CompletedSignUp />
      ) : (
        <FormProvider {...form}>
          <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
            <AuthLayout>
              <>
                <Grid
                  container
                  flexDirection="column"
                  alignItems="center"
                  sx={{ maxWidth: '500px', margin: '50px 0' }}
                >
                  <Typography
                    sx={{
                      maxWidth: { xs: '200px', sm: '100%' },
                      marginBottom: '5px',
                      ...fontStyles.h3,
                      color: colors.main.white,
                      textAlign: { xs: 'center', sm: 'unset' },
                    }}
                  >
                    See how much you can make
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: colors.system.error,
                      height: '20px',
                      width: '100%',
                      textAlign: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    {message}
                  </Typography>
                  <StyledInputContainer>
                    {showArtistInput ? (
                      <AutocompleteInputController
                        dark
                        withError
                        name="artistSpotifyId"
                        options={artistList}
                        defaultValue={defaultArtistName}
                        label="Search Artist or enter Spotify ID"
                        handleChange={handleSearchArtist}
                      />
                    ) : null}
                  </StyledInputContainer>
                  <StyledInputContainer>
                    <BaseInputController
                      dark
                      withError
                      name="email"
                      type="email"
                      variant="filled"
                      label="Your email"
                      defaultValue={defaultEmail}
                      onChange={() => {
                        handleInputFieldInteractionEvent();
                      }}
                    />
                  </StyledInputContainer>
                  <StyledInputContainer>
                    <BaseInputController
                      dark
                      withError
                      name="fullName"
                      variant="filled"
                      label="Full name"
                      onChange={() => {
                        handleInputFieldInteractionEvent();
                      }}
                    />
                  </StyledInputContainer>
                  <StyledInputContainer>
                    <PasswordInputController
                      dark
                      withError
                      name="password"
                      variant="filled"
                      label="Create password"
                    />
                  </StyledInputContainer>
                  <StyledInputContainer>
                    <PasswordInputController
                      dark
                      withError
                      variant="filled"
                      name="passwordConfirm"
                      label="Confirm password"
                    />
                  </StyledInputContainer>
                  <StyledButton loading={isSubmitting} type="submit">
                    Lets go
                  </StyledButton>
                  <Grid container justifyContent="center" alignItems="baseline">
                    <StyledText>Have an account?</StyledText>{' '}
                    <StyledLink to="/sign-in">Login</StyledLink>
                  </Grid>
                </Grid>
                <TalkToUsLink isAuth />
              </>
            </AuthLayout>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default SignUp;
