import React, { createContext, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

export interface IUserData {
  id?: number;
  email?: string;
  fullName?: string | null;
  address?: string | null;
  EPK_link?: string | null;
  createdAt?: string;
  updatedAt?: string;
  isAuthorized: boolean | null;
}

export interface ITrack {
  dateReleased: string;
  isCustom: boolean;
  name: string;
  ownership: number;
  trackSpotifyId: string;
}

export interface IReport {
  createdAt: string;
  deal_id: number;
  distributor: string;
  hubspotFileId: string;
  hubspotFileUrl: string;
  id: number;
  name: string;
  status: string;
  url: string;
}

export interface IDeal {
  // stable fields
  id: number;
  artistName: string;
  artistSpotifyId: string;
  createdAt: string;
  isEntireCatalogue: boolean;
  status: string;
  images: {
    width: number;
    height: number;
    url: string;
  }[];
  // unstable fields
  amount: number | null;
  artistHomeRate: number | null;
  estimatedAmountMax: number | null;
  estimatedAmountMin: number | null;
  lengthOfCommitment: number | null;
  submittedDate: string | null;
  totalRevenueLastMonth: number | null;
  totalStreamsLastMonth: number | null;
  tracks: ITrack[];
  reports: IReport[];
  advanceMax?: number | null;
  advanceMin?: number | null;
  calculatedAmounts: { total_streams: number; min_revenue: number; max_revenue: number }[];
}

interface IContextProps {
  userData: IUserData;
  updateUserData: (values: IUserData) => void;
  logOut: () => void;
}

const UserDataContext = createContext({} as IContextProps);

export const useUserData = () => useContext(UserDataContext);

export const UserDataProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const history = useHistory();

  const [userData, setUserData] = useState<IUserData>({ isAuthorized: null });

  const updateUserData = useCallback((values: IUserData) => {
    setUserData((prevState) => ({ ...prevState, ...values }));
  }, []);

  const logOut = useCallback(() => {
    localStorage.removeItem('Authorization');
    setUserData({ isAuthorized: false });
    history.push('/');
  }, [history]);

  return (
    <UserDataContext.Provider
      value={{
        userData,
        updateUserData,
        logOut,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};
