import React from 'react';

import { Box } from '@mui/material';
import { BoxProps, keyframes } from '@mui/system';

interface IProps extends BoxProps {
  children: React.ReactNode;
}

interface IAnimatedItem extends IProps {
  to?: string;
  from?: string;
  delay?: string;
  duration?: string;
  visible?: boolean;
  animateOpacity?: boolean;
  mode?: 'forwards' | 'backwards' | 'both';
  animation?: 'ease-out' | 'ease-in' | 'ease-in-out';
}

interface ICutOutAnimator extends React.FC<IProps> {
  AnimatedItem: React.FC<IAnimatedItem>;
}

interface IRenderAnimationFunc {
  animation: string;
  suffix: string;
  delay: string;
  visible: boolean;
}

const renderAnimation = ({ animation, suffix, delay, visible }: IRenderAnimationFunc): object => {
  if (visible) {
    return {
      animation: `${keyframes(`${animation}`)} ${suffix}`,
      animationDelay: delay,
    };
  }

  return {};
};

const CutOutAnimator: ICutOutAnimator = ({ children, ...rest }) => {
  return (
    <Box position="relative" overflow="hidden" {...rest}>
      {children}
    </Box>
  );
};

CutOutAnimator.AnimatedItem = ({
  sx = {},
  children,
  delay = '0ms',
  duration = '500ms',
  visible = true,
  mode = 'forwards',
  animation = 'ease-out',
  animateOpacity = false,
  from = 'transform: translateY(100%);',
  to = 'transform: translateY(0px);',
  ...rest
}) => {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      sx={{
        transform: 'translateY(100%)',
        opacity: animateOpacity ? 0 : 1,
        ...renderAnimation({
          animation: `
            from { 
              ${from}
              ${animateOpacity ? 'opacity: 0;' : ''}
            } to { 
              ${to}
              ${animateOpacity ? 'opacity: 1;' : ''}
            }
          `,
          suffix: `${duration} ${mode} ${animation}`,
          delay,
          visible,
        }),
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default CutOutAnimator;
