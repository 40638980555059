import { useRef, useState, useCallback } from 'react';
import { colors } from 'styles/colors';
import { useWidth } from 'hooks/useWidth';
import useOnScreen from 'hooks/useOnScreen';
import { Box, Container, Grid, debounce } from '@mui/material';
import { useForm, FormProvider, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import AutocompleteInputController from 'controllers/autocomplete-input-controller';
import BaseInputController from 'controllers/base-input-controller';
import PurpleButton from 'components/purple-button';
import { SignUpSchema } from './validation';
import api from 'utils/api';
import Text from 'components/text';
import BarAnimator from 'components/bar-animation';
import CutOutAnimator from 'components/cut-out-animation';
import { GaEvents, GaEventCategory } from 'utils/gaEvents';
import SlideUpAnimation from 'components/slideup-animation';

import * as yup from 'yup';
import { useError } from 'hooks/useError';
import { IDeal } from 'hooks/useUserData';
import ErrorModal from 'components/error-modal';
import { LoadingButton, loadingButtonClasses } from '@mui/lab';

const SchemaForAuthEstForm = yup.object({
  artistSpotifyId: yup.string().nullable().required('Field is required'),
});

const StyledButtonForAuthEstForm = styled(LoadingButton)(({ theme }) => ({
  height: '48px',
  margin: '0 !important',
  textTransform: 'unset',
  boxShadow: 'none',
  [`&.${loadingButtonClasses.loading}`]: {
    backgroundColor: theme.colors.main.purpleDark,
  },
  [`& .${loadingButtonClasses.loadingIndicator}`]: {
    color: '#9FA5AE',
  },
}));

const StyledButton = styled(PurpleButton)(() => ({
  margin: '8px 0px',
}));

interface IArtistList {
  label: string;
  value: number | string;
}

interface ISpotifyData {
  items: {
    id: number;
    name: string;
  }[];
  id: number;
  name: string;
}

const HeadingText = () => {
  return (
    <Text
      sx={{
        textTransform: 'uppercase',
      }}
      fontSize="64px"
      textAlign="center"
      marginBottom="36px"
    >
      <Text component="span" color="#FEDC00" beniRegular letterSpacing="1.5px">
        Funding.
      </Text>{' '}
      <Text component="span" beniRegular letterSpacing="1.5px" color="#DEDEDE">
        Simplified.
      </Text>
    </Text>
  );
};

const SubText = () => {
  return (
    <Text textAlign="center" fontWeight={600} fontSize="24px">
      <Text
        component="span"
        color="#9FA5AE"
        display={{
          xs: 'block',
          md: 'inline',
        }}
      >
        Get an advance on your
      </Text>{' '}
      <Text
        component="span"
        display={{
          xs: 'block',
          md: 'inline',
        }}
        color="#9FA5AE"
      >
        streaming royalties
      </Text>
    </Text>
  );
};

const EstimationForm = () => {
  const history = useHistory();
  const [hasInteracted, setHasInteracted] = useState(false);
  const [artistList, setArtistList] = useState<IArtistList[]>([]);
  const form = useForm({ resolver: yupResolver(SignUpSchema) });

  const handleInputFieldInteractionEvent = useCallback(() => {
    if (!hasInteracted) {
      setHasInteracted(true);
      GaEvents[GaEventCategory.LANDING_PAGE].formInteraction();
    }
  }, [hasInteracted]);

  const searchArtist = useCallback(
    async (value: string) => {
      handleInputFieldInteractionEvent();
      const data: ISpotifyData = await api.spotify.getSpotifyArtist({ id: value });
      const formattedData =
        data.items && Array.isArray(data.items)
          ? data.items.map(({ id, name }) => ({ label: name, value: id }))
          : [{ label: data.name, value: data.id }];
      setArtistList(formattedData);
    },
    [handleInputFieldInteractionEvent]
  );

  const handleSearchArtist = debounce(searchArtist, 300);

  const onSubmit = (formValues: FieldValues) => {
    const { artistSpotifyId, email } = formValues;
    const artistName: string | undefined = artistList.find(
      (artist) => artist.value === artistSpotifyId
    )?.label;
    history.push(
      `/sign-up?a=${encodeURIComponent(artistSpotifyId)}&e=${encodeURIComponent(
        email
      )}&n=${encodeURIComponent(artistName ? artistName : '')}&prf=1`
    );
  };

  return (
    <FormProvider {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container flexDirection="column" alignItems="center">
          <Grid container flexDirection="column">
            <AutocompleteInputController
              dark
              withError
              name="artistSpotifyId"
              options={artistList}
              label="Search Artist or enter Spotify ID"
              handleChange={handleSearchArtist}
            />
          </Grid>
          <Grid container flexDirection="column">
            <BaseInputController
              dark
              withError
              name="email"
              type="email"
              variant="filled"
              label="Your email"
              onChange={() => {
                handleInputFieldInteractionEvent();
              }}
            />
          </Grid>

          <Grid container flexDirection="column">
            <StyledButton type="submit">Get free estimate</StyledButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

const EstimationFormAuthUser = (props: any) => {
  const { message, setError } = useError();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const history = useHistory();
  const [artistList, setArtistList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm({ resolver: yupResolver(SchemaForAuthEstForm) });

  const searchArtist = useCallback(
    async (value: string) => {
      if (!value.trim()) return;
      try {
        const data: any = await api.spotify.getSpotifyArtist({ id: value });

        const formattedData: any =
          data.items && Array.isArray(data.items)
            ? data.items.map(({ id, name, images }: any) => ({ label: name, value: id, images }))
            : [{ label: data.name, value: data.id, images: data.images }];

        setArtistList(formattedData);
      } catch (err) {
        setError(
          "Something went wrong while searching for the artist's name. Please refresh this page and try again."
        );
        setOpenErrorModal(true);
      }
    },
    [setError]
  );

  const handleSearchArtist = debounce(searchArtist, 300);

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        setIsLoading(true);

        const selectedArtist: any = artistList.filter(
          ({ value }) => value === data.artistSpotifyId
        )[0];

        const formData: any = {
          ...data,
          artistName: selectedArtist.label,
          images: selectedArtist.images,
        };

        const newDeal: IDeal = await api.deals.createDeal({ data: formData });

        form.reset();

        history.push(`/deal/${newDeal.id}`);
      } catch (err) {
        setError(err);
        setOpenErrorModal(true);
      } finally {
        setIsLoading(false);
      }
    },
    [artistList, form, history, setError]
  );

  return (
    <Box>
      <FormProvider {...form}>
        <Grid container justifyContent="center">
          <Text
            component="span"
            color="#9FA5AE"
            marginBottom="24px"
            fontSize="20px"
            lineHeight="28px"
          >
            Search for artist
          </Text>
        </Grid>
        <Grid container flexDirection="column">
          <AutocompleteInputController
            dark
            withError
            name="artistSpotifyId"
            options={artistList}
            label="Search Artist or enter Spotify ID"
            handleChange={handleSearchArtist}
          />
        </Grid>
        <StyledButtonForAuthEstForm
          fullWidth
          variant="contained"
          loading={isLoading}
          onClick={form.handleSubmit(onSubmit)}
          sx={{
            backgroundColor: colors.main.purple,
            '&:hover': {
              backgroundColor: colors.main.purpleDark,
            },
          }}
        >
          Let's go
        </StyledButtonForAuthEstForm>
      </FormProvider>
      <ErrorModal
        title="Ops!"
        message={message}
        open={openErrorModal}
        handleClose={() => setOpenErrorModal(false)}
      />
    </Box>
  );
};

export default function EstimateSection(props: any) {
  const { isAuthorized } = props;
  const ref = useRef(null);
  const { isMobile } = useWidth();
  const isVisible = useOnScreen(ref, isMobile ? '-50px' : '-100px');

  return (
    <Grid container marginTop="80px" bgcolor="#0E1014">
      <Container maxWidth="lg" ref={ref}>
        <Grid container paddingY="120px">
          <Grid item xs={12} md={6}>
            <Box height="100%" display="flex" flexDirection="column" justifyContent="center">
              <CutOutAnimator height="60px" zIndex={2}>
                <CutOutAnimator.AnimatedItem delay="600ms" visible={isVisible}>
                  <HeadingText />
                </CutOutAnimator.AnimatedItem>
              </CutOutAnimator>

              <BarAnimator zIndex={1}>
                <BarAnimator.Bar
                  visible={isVisible}
                  from="width: 0%;"
                  to="width: 24%;"
                  height="24px"
                  width="0%"
                  bottom={-5}
                  left={{
                    xs: '10%',
                    sm: '25%',
                    md: '20%',
                  }}
                  bgcolor={colors.main.purple}
                />
              </BarAnimator>

              <CutOutAnimator
                height={{
                  xs: '60px',
                  md: '32px',
                }}
                zIndex={2}
                marginY="36px"
              >
                <CutOutAnimator.AnimatedItem delay="750ms" duration="1s" visible={isVisible}>
                  <SubText />
                </CutOutAnimator.AnimatedItem>
              </CutOutAnimator>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <SlideUpAnimation
              marginY="36px"
              delay="500ms"
              duration="1s"
              distance="35px"
              visible={isVisible}
            >
              {isAuthorized ? <EstimationFormAuthUser /> : <EstimationForm />}
            </SlideUpAnimation>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
