import React from 'react';
// mui component
import { Grid, Typography } from '@mui/material';
// components
import ChangedInput from '../../components/changed-input';
import ChangedPasswordInput from '../../components/changed-password-input';
// styles
import { fontStyles } from 'styles/font-styles';
import { colors } from 'styles/colors';

const Setting: React.FC = () => {
  return (
    <Grid
      container
      flexDirection="column"
      sx={{ maxWidth: '500px', margin: { xs: '16px', md: '40px' } }}
    >
      <Typography sx={{ margin: '14px 0', ...fontStyles.h3, color: colors.text.light.title }}>
        Setting
      </Typography>
      <ChangedInput name="email" label="Email" type="email" />
      <ChangedPasswordInput />
    </Grid>
  );
};

export default Setting;
