import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
// mui components
import { Grid, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import {
  StyledText,
  StyledLabel,
  StyledValue,
  StyledAmount,
  StyledHeading,
} from '../sections/styled-components';
// images
import star from 'assets/icons/star.svg';
// icons
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow_left.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
// utils
import { formatValueToDollars } from 'utils/helpers';
// types
import { IDeal } from 'hooks/useUserData';
// styles
import { colors } from 'styles/colors';
import { GaEvents, GaEventCategory } from 'utils/gaEvents';

const StyledLink = styled(Link)(({ theme }) => ({
  ...theme.fontStyles.subtext,
  color: theme.colors.text.light.label,
}));

const StyledLinkIcon = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '30px',
});

const DealItem: React.FC<IDeal> = ({
  id,
  artistName,
  status,
  submittedDate,
  images,
  advanceMin,
  advanceMax,
}) => {
  const itemIsCompleted = useMemo(() => status === 'submitted', [status]);

  const statusLabel = useMemo(
    () => (itemIsCompleted ? 'Submitted' : 'In progress'),
    [itemIsCompleted]
  );

  const linkLabel = useMemo(
    () => (itemIsCompleted ? 'View details' : 'Continue'),
    [itemIsCompleted]
  );

  const linkValue = useMemo(
    () => (itemIsCompleted ? `/profile/deals/${id}` : `/deal/${id}`),
    [itemIsCompleted, id]
  );

  const helperText = useMemo(
    () =>
      itemIsCompleted
        ? 'Your deals has been accepted'
        : "You'll need to add few more details before you can submit the deal",
    [itemIsCompleted]
  );

  return (
    <Grid
      container
      sx={{
        padding: '32px',
        marginBottom: '32px',
        border: `1px solid ${colors.background.light.b4}`,
        borderRadius: '8px',
        boxShadow: '2px 1px 15px rgba(44, 59, 70, 0.2)',
        transition: 'box-shadow 0.4s ease-in-out',
        '&:hover': {
          boxShadow: '18px 18px 10px rgb(44 59 70 / 20%)',
        },
      }}
    >
      <Grid container item md={2} xs={12}>
        <Avatar src={images[0]?.url || star} alt="avatar" sx={{ width: '88px', height: '88px' }} />
      </Grid>
      <Grid container flexDirection="column" item md={10}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingBottom: '16px',
            '& svg': {
              width: '16px',
              height: '16px',
              transform: 'rotate(180deg)',
            },
            alignItems: { xs: 'flex-end', md: 'center' },
          }}
        >
          <StyledHeading sx={{ paddingTop: { xs: '20px', md: '0' } }}>
            {artistName || '-'}
          </StyledHeading>
          <StyledLinkIcon to={linkValue}>
            <ArrowLeftIcon />
          </StyledLinkIcon>
        </Grid>
        <Grid
          container
          alignItems="center"
          sx={{
            padding: '16px 0',
            borderTop: `1px solid ${colors.background.light.b4}`,
            borderBottom: `1px solid ${colors.background.light.b4}`,
          }}
        >
          <Grid
            container
            flexDirection="column"
            item
            xs={12}
            md={4}
            sx={{ marginBottom: { xs: '15px', md: '0' } }}
          >
            <StyledLabel>{itemIsCompleted ? 'Amount:' : 'Estimated amount:'}</StyledLabel>
            <StyledValue paddingRight="12px">
              <StyledAmount as="span">{formatValueToDollars(advanceMin ?? 0)}</StyledAmount> -{' '}
              <StyledAmount as="span">{formatValueToDollars(advanceMax ?? 0)}</StyledAmount>
            </StyledValue>
          </Grid>
          <Grid
            container
            flexDirection="column"
            item
            xs={12}
            md={4}
            sx={{ marginBottom: { xs: '15px', md: '0' } }}
          >
            <StyledLabel>Status:</StyledLabel>
            <StyledText>
              {statusLabel} {itemIsCompleted && <CheckIcon />}
            </StyledText>
          </Grid>
          <Grid container flexDirection="column" item xs={12} md={4}>
            <StyledLabel>Submitted date:</StyledLabel>
            <StyledValue>{submittedDate || '-'}</StyledValue>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ paddingTop: '16px' }}
        >
          <StyledText>{helperText}</StyledText>
          <StyledLink
            to={linkValue}
            onClick={() => {
              GaEvents[GaEventCategory.DASHBOARD].exploreDeal();
            }}
          >
            {linkLabel}
          </StyledLink>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DealItem;
