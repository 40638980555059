import React, { memo, useCallback, useEffect } from 'react';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { Link, useHistory } from 'react-router-dom';
// mui components
import { CircularProgress, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// layouts
import AuthLayout from 'layouts/auth-layout';
// components
import PurpleButton from 'components/purple-button';
// custom hooks
import { useGetDealList } from 'hooks/swrRequests';
// icons
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
// styles
import { colors } from 'styles/colors';

const StyledHeading = styled(Typography)(({ theme }) => ({
  margin: '40px 0 35px',
  ...theme.fontStyles.h3,
  color: theme.colors.text.light.title,
}));

const StyledText = styled(Typography)(({ theme }) => ({
  ...theme.fontStyles.subtext,
  color: theme.colors.text.light.label,
}));

const StyledList = styled('ul')({
  margin: '24px 0 32px',
});

const renderer = ({ seconds }: CountdownRenderProps) => (
  <StyledText>Continue in ({seconds}s)</StyledText>
);

interface IHistory {
  showIntro?: boolean;
}

const Intro: React.FC = () => {
  const history = useHistory<IHistory>();
  const { data: deals } = useGetDealList();

  useEffect(() => {
    if (!history.location.state?.showIntro) {
      history.push('/');
    } else {
      history.replace('/intro', {});
    }
  }, [history]);

  const handleGoToDeal = useCallback(() => {
    history.push(`/deal/${deals?.[0].id}`);
  }, [history, deals]);

  if (!deals?.length) {
    return (
      <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
        <CircularProgress size={70} sx={{ color: colors.main.purple }} />
      </Grid>
    );
  }

  return (
    <AuthLayout>
      <Grid
        container
        flexDirection="column"
        alignItems="center"
        sx={{
          maxWidth: '480px',
          padding: '43px 40px 24px',
          backgroundColor: colors.main.white,
          borderRadius: '8px',
          zIndex: 1,
        }}
      >
        <SuccessIcon />
        <StyledHeading>Your account is ready!</StyledHeading>
        <StyledText>Just a few step to create your first deal</StyledText>
        <StyledList>
          <li>
            <StyledText>Select what songs you'd like to receive an offer for</StyledText>
          </li>
          <li>
            <StyledText>Customize your deal terms, receive estimate</StyledText>
          </li>
          <li>
            <StyledText>Confirm song ownership and receive final offer</StyledText>
          </li>
        </StyledList>
        <PurpleButton
          component={Link}
          to={`/deal/${deals?.[0].id}`}
          sx={{ width: '184px', marginBottom: '32px' }}
        >
          Start my first deal
        </PurpleButton>
        <Countdown date={Date.now() + 10000} renderer={renderer} onComplete={handleGoToDeal} />
      </Grid>
    </AuthLayout>
  );
};

export default memo(Intro);
