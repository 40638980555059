import React, { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Grid,
  debounce,
} from '@mui/material';
import { LoadingButton, loadingButtonClasses } from '@mui/lab';
import { styled } from '@mui/material/styles';
import ErrorModal from 'components/error-modal';
import AutocompleteInputController from 'controllers/autocomplete-input-controller';
import { useError } from 'hooks/useError';
import api from 'utils/api';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { Schema } from './validation';
import { colors } from 'styles/colors';
import { IDeal } from '../../../../../../hooks/useUserData';

const StyledDialog = styled(Dialog)({
  '& .MuiDialogContent-root': {
    margin: '26px 0 16px',
    padding: 0,
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'center',
    height: '48px',
    padding: 0,
    '& button': {
      margin: '0 12px',
    },
  },
  '& .MuiDialog-paper': {
    width: '480px',
    padding: '24px',
    borderRadius: '8px',
    backgroundColor: colors.main.black,
    overflow: 'visible',
  },
});

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const StyledDialogTitle: React.FC<DialogTitleProps> = (props) => {
  const { onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ display: 'flex', justifyContent: 'flex-end', height: '40px', padding: 0 }}
      {...other}
    >
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ width: '40px', height: '40px', '& path': { fill: colors.main.white } }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  height: '48px',
  margin: '0 !important',
  textTransform: 'unset',
  boxShadow: 'none',
  [`&.${loadingButtonClasses.loading}`]: {
    backgroundColor: theme.colors.main.purpleDark,
  },
  [`& .${loadingButtonClasses.loadingIndicator}`]: {
    color: theme.colors.main.white,
  },
}));

interface IArtistList {
  label: string;
  value: number | string;
  images: {
    width: number;
    height: number;
    url: string;
  }[];
}

interface ISpotifyData {
  items: {
    id: number;
    name: string;
    images: {
      width: number;
      height: number;
      url: string;
    }[];
  }[];
  id: number;
  name: string;
  images: {
    width: number;
    height: number;
    url: string;
  }[];
}

interface OwnProps {
  open: boolean;
  handleClose: () => void;
}

const CreateDealModal: React.FC<OwnProps> = ({ open, handleClose }) => {
  const { message, setError } = useError();

  const [openErrorModal, setOpenErrorModal] = useState(false);

  const history = useHistory();

  const [artistList, setArtistList] = useState<IArtistList[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({ resolver: yupResolver(Schema) });

  const searchArtist = useCallback(
    async (value: string) => {
      if (!value.trim()) return;
      try {
        const data: ISpotifyData = await api.spotify.getSpotifyArtist({ id: value });

        const formattedData =
          data.items && Array.isArray(data.items)
            ? data.items.map(({ id, name, images }) => ({ label: name, value: id, images }))
            : [{ label: data.name, value: data.id, images: data.images }];

        setArtistList(formattedData);
      } catch (err) {
        setError(
          "Something went wrong while searching for the artist's name. Please refresh this page and try again."
        );
        setOpenErrorModal(true);
      }
    },
    [setError]
  );

  const handleSearchArtist = debounce(searchArtist, 300);

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        setIsLoading(true);

        const selectedArtist = artistList.filter(({ value }) => value === data.artistSpotifyId)[0];

        const formData = {
          ...data,
          artistName: selectedArtist.label,
          images: selectedArtist.images,
        };

        const newDeal: IDeal = await api.deals.createDeal({ data: formData });

        handleClose();

        form.reset();

        history.push(`/deal/${newDeal.id}`);
      } catch (err) {
        setError(err);
        setOpenErrorModal(true);
      } finally {
        setIsLoading(false);
      }
    },
    [artistList, handleClose, form, history, setError]
  );

  return (
    <>
      <StyledDialog
        open={open}
        disablePortal
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <FormProvider {...form}>
          <StyledDialogTitle id="customized-dialog-title" onClose={handleClose} />
          <DialogContent>
            <Grid container justifyContent="center">
              <Typography
                sx={{
                  marginBottom: '24px',
                  fontSize: '32px',
                  lineHeight: '28px',
                  fontWeight: 'bold',
                  color: colors.main.white,
                }}
              >
                Search for artist
              </Typography>
            </Grid>
            <Grid container flexDirection="column">
              <AutocompleteInputController
                dark
                withError
                name="artistSpotifyId"
                options={artistList}
                label="Search Artist or enter Spotify ID"
                handleChange={handleSearchArtist}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <StyledButton
              fullWidth
              variant="contained"
              loading={isLoading}
              onClick={form.handleSubmit(onSubmit)}
              sx={{
                backgroundColor: colors.main.purple,
                '&:hover': {
                  backgroundColor: colors.main.purpleDark,
                },
              }}
            >
              Let's go
            </StyledButton>
          </DialogActions>
        </FormProvider>
      </StyledDialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={() => setOpenErrorModal(false)}
        message={message}
      />
    </>
  );
};

export default CreateDealModal;
