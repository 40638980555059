import { colors } from 'styles/colors';
import { useWidth } from 'hooks/useWidth';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import logoSvg from 'assets/icons/fineart_logo.svg';
import menuIcon from 'assets/icons/menu.svg';
import { Drawer, Grid, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { GaEvents, GaEventCategory } from 'utils/gaEvents';

const SHARED_MENU_STYLES = {
  cursor: 'pointer',
  opacity: 0.6,
  transition: 'opacity 0.2s ease-in-out',
  fontFamily: "'Founders Grotesk', sans serif",
  color: colors.text.dark.title,
  fontWeight: 600,
  '&:hover, &.active': {
    opacity: 1,
  },
};

const StyledMobileNav = styled(Box)(() => ({
  fontSize: '18px',
  padding: '15px 0px',
  ...SHARED_MENU_STYLES,
}));

const StyledMobileNavLink = styled(Link)(() => ({
  display: 'block',
  textDecoration: 'none',
  fontSize: '18px',
  padding: '15px 0px',
  ...SHARED_MENU_STYLES,
}));

const StyledDesktopNav = styled(Box)(() => ({
  fontSize: '16px',
  ...SHARED_MENU_STYLES,
}));

const StyledDesktopNavLink = styled(Link)(() => ({
  fontSize: '16px',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: colors.text.dark.title,
  padding: '9px 24px',
  borderRadius: '24px',
  textDecoration: 'none',
  ...SHARED_MENU_STYLES,
}));

const navigateById = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

const MobileNavbar = (props: { isAuthorized: boolean | null }) => {
  const { isAuthorized } = props;
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    // console.log(open);
    setOpen(!open);
  };

  return (
    <>
      <Drawer open={open} onClose={toggleDrawer} variant="temporary">
        <Box
          paddingY={2.5}
          paddingX={3}
          sx={{
            width: '65vw',
            height: '100%',
            backgroundColor: colors.background.dark.darkBlue,
          }}
        >
          <Box
            paddingBottom={2.5}
            component={'img'}
            src={logoSvg}
            onClick={() => {
              toggleDrawer();
              navigateById('intro-section');
            }}
            sx={{
              width: '70px',
              cursor: 'pointer',
              '& path': {
                fill: colors.main.white,
              },
            }}
          />
          <Box>
            <StyledMobileNav
              onClick={() => {
                toggleDrawer();
                navigateById('aboutus-section');
              }}
            >
              About us
            </StyledMobileNav>
            <StyledMobileNav
              onClick={() => {
                toggleDrawer();
                navigateById('faq-section');
              }}
            >
              FAQ
            </StyledMobileNav>
            {isAuthorized ? (
              <StyledMobileNavLink
                to="/profile/deals"
                onClick={() => {
                  GaEvents[GaEventCategory.LANDING_PAGE].myDealsLink();
                }}
              >
                My Deals
              </StyledMobileNavLink>
            ) : (
              <>
                <StyledMobileNavLink to="/sign-up">Sign up</StyledMobileNavLink>
                <StyledMobileNavLink to="/sign-in">Sign in</StyledMobileNavLink>
              </>
            )}
          </Box>
        </Box>
      </Drawer>
      <Grid
        container
        paddingX={3}
        paddingY={2.5}
        position="sticky"
        top={0}
        left={0}
        zIndex={9}
        sx={{
          backgroundColor: colors.main.black,
        }}
      >
        <Grid item xs={2}>
          <Link
            to={'/'}
            onClick={() => {
              navigateById('intro-section');
            }}
          >
            <Box
              component={'img'}
              src={logoSvg}
              sx={{
                width: '70px',
                '& path': {
                  fill: colors.main.white,
                },
              }}
            />
          </Link>
        </Grid>
        <Grid item xs={10}>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap={4}
            sx={{ height: '100%' }}
          >
            <Box
              component={'img'}
              src={menuIcon}
              onClick={toggleDrawer}
              sx={{
                width: '24px',
                '& path': {
                  fill: colors.main.white,
                },
                cursor: 'pointer',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const DesktopNavbar = (props: { isAuthorized: boolean | null }) => {
  const { isAuthorized } = props;

  return (
    <Box
      position="sticky"
      top={0}
      left={0}
      zIndex={9}
      display="flex"
      maxWidth="1600px"
      width="100%"
      paddingX="8%"
      paddingY="20px"
    >
      <Box>
        <Link to={'/'} onClick={() => navigateById('intro-section')}>
          <Box
            component={'img'}
            src={logoSvg}
            sx={{
              width: '70px',
              '& path': {
                fill: colors.main.white,
              },
            }}
          />
        </Link>
      </Box>
      <Box display="flex" flexGrow={1} justifyContent="flex-end" alignItems="center" gap={6}>
        <StyledDesktopNav onClick={() => navigateById('aboutus-section')}>
          About us
        </StyledDesktopNav>
        <StyledDesktopNav onClick={() => navigateById('faq-section')}>FAQ</StyledDesktopNav>
        {isAuthorized ? (
          <StyledDesktopNavLink to="/profile/deals">My Deals</StyledDesktopNavLink>
        ) : (
          <StyledDesktopNavLink
            to="/sign-up"
            onClick={() => {
              GaEvents[GaEventCategory.LANDING_PAGE].topAuthLink();
            }}
          >
            Sign up
          </StyledDesktopNavLink>
        )}
      </Box>
    </Box>
  );
};

const LandingHeader = (props: { isAuthorized: boolean | null }) => {
  const { isMobile } = useWidth();

  return isMobile ? (
    <MobileNavbar isAuthorized={props.isAuthorized} />
  ) : (
    <DesktopNavbar isAuthorized={props.isAuthorized} />
  );
};

export default LandingHeader;
