import { useCallback, useEffect, useState } from 'react';
import { Box, Grid, InputAdornment, CircularProgress } from '@mui/material';
import Text from 'components/text';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import FaqSupportLink from '../FaqSupportLink';
import Footer from '../Footer';
import SubmitButton from '../SubmitButton';
import BaseInputController from 'controllers/base-input-controller';
import { formatValueToDollars } from 'utils/helpers';
import api from 'utils/api';
import { GaEvents, GaEventCategory } from 'utils/gaEvents';
import ErrorModal from 'components/error-modal';
import { useError } from 'hooks/useError';

const errorMessage = 'Valid estimation is required and must be a valid number';

export const ValidationSchema = yup.object({
  ownEstimation: yup.number().typeError(errorMessage).required(errorMessage),
});

interface IProps {
  state: any;
  setFormData(payload: object): object;
  setCurrentStepNumber(nextStep: number): void;
}

export default function ReviewInformation(props: IProps) {
  const { state, setFormData, setCurrentStepNumber } = props;
  const { deal, currentStepNumber, form: formState } = state;
  const { maxRevenueLastMonthAllTracks, minRevenueLastMonthAllTracks, revenueLastMonthAllTracks } =
    deal;
  const [, image] = deal?.images;
  const [loading, setLoading] = useState(false);
  const [defaultOwnEstimation, setDefaultOwnEstimation] = useState(0);
  const form = useForm({ resolver: yupResolver(ValidationSchema) });
  const { message, setError } = useError();
  const [openErrorModal, setOpenErrorModal] = useState(false);

  useEffect(() => {
    GaEvents[GaEventCategory.REVIEW_SCREEN].viewed();
  }, []);

  useEffect(() => {
    const ownEstimation = formState?.ownEstimation || revenueLastMonthAllTracks;
    form.setValue('ownEstimation', ownEstimation);
    setDefaultOwnEstimation(ownEstimation);
    // eslint-disable-next-line
  }, []);

  const trackEstimatedRevenueChange = useCallback(
    (ownEstimation: number) => {
      const previousOwnEstimation = deal.ownEstimation || 0;

      if (ownEstimation !== revenueLastMonthAllTracks && ownEstimation !== previousOwnEstimation) {
        GaEvents[GaEventCategory.REVIEW_SCREEN].estimatedRevenueChanged();
      }
    },
    [deal, revenueLastMonthAllTracks]
  );

  const onSubmit = useCallback(
    async (formValues: object) => {
      setLoading(true);
      const { ownEstimation: ownEstimationFieldValue }: any = formValues;
      const ownEstimation = parseInt(ownEstimationFieldValue);
      typeof setFormData === 'function' && setFormData({ ownEstimation });
      try {
        await api.deals.updateDeal({
          id: deal.id,
          data: { ownEstimation },
        });
        trackEstimatedRevenueChange(ownEstimation);
        GaEvents[GaEventCategory.REVIEW_SCREEN].confirmed();
        setCurrentStepNumber(2);
      } catch (err) {
        setError(
          'Something went wrong while updating the revenue. Please refresh this page and try again.'
        );
        setOpenErrorModal(true);
      } finally {
        setLoading(false);
      }
    },
    [deal, setFormData, setCurrentStepNumber, setError, trackEstimatedRevenueChange]
  );

  return (
    <>
      <Box
        paddingTop={{
          xs: '12px',
          sm: '22px',
        }}
        paddingBottom="120px"
      >
        <Text textAlign="center" fontSize="32px" lineHeight="40px" fontWeight="bold">
          Review Information
        </Text>
        <Text textAlign="center" fontSize="18px" lineHeight="26px" marginTop="8px">
          We collect public data from streaming services to estimate your revenue.
          <br />
          For a more accurate advance offer, add your total revenue from streaming last month
        </Text>

        <Box
          bgcolor="#F4F5F7"
          width="100%"
          maxWidth="500px"
          marginTop="32px"
          borderRadius="8px"
          marginX="auto"
        >
          <Box padding="40px">
            <Box textAlign="center">
              <Box
                component="img"
                width="64px"
                height="64px"
                alt="artist image"
                borderRadius="50%"
                src={image?.url}
              />
            </Box>
            <Text
              textAlign="center"
              marginTop="4px"
              fontSize="20px"
              lineHeight="30px"
              fontWeight="bold"
            >
              {deal?.artistName}
            </Text>

            <Text textAlign="center" fontSize="18px" lineHeight="26px" color="#484848">
              Total estimated revenue last month
            </Text>
            <Text
              textAlign="center"
              fontSize="20px"
              lineHeight="26px"
              color="#5800AF"
              marginTop="4px"
              letterSpacing="0.6px"
            >
              {formatValueToDollars(minRevenueLastMonthAllTracks)} -{' '}
              {formatValueToDollars(maxRevenueLastMonthAllTracks)}
            </Text>

            <Box width="48px" borderTop={1} borderColor="#2B2B2B" marginX="auto" marginY="24px" />

            <Text
              textAlign="center"
              fontSize="18px"
              lineHeight="26px"
              color="#484848"
              marginTop="4px"
            >
              Add your own estimation (approximate is ok)
            </Text>
            <FormProvider {...form}>
              <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
                <Grid container flexDirection="column" alignItems="center" marginTop="8px">
                  <BaseInputController
                    withError
                    name="ownEstimation"
                    type="text"
                    inputMode="numeric"
                    variant="filled"
                    label="Last month revenue"
                    style={{
                      width: '100%',
                      maxWidth: '240px',
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    defaultValue={defaultOwnEstimation}
                  />
                </Grid>
                <Footer
                  currentStepNumber={currentStepNumber}
                  setCurrentStepNumber={setCurrentStepNumber}
                >
                  <SubmitButton type="submit" disabled={loading}>
                    {loading ? (
                      <Box minWidth="120px" paddingTop="7px">
                        <CircularProgress sx={{ color: '#ddd' }} size="24px" />
                      </Box>
                    ) : (
                      <Text
                        fontSize="20px"
                        fontWeight="bold"
                        color="#fff"
                        paddingX="24px"
                        paddingY="9px"
                      >
                        Confirm
                      </Text>
                    )}
                  </SubmitButton>
                </Footer>
              </form>
            </FormProvider>
          </Box>
        </Box>
      </Box>
      <FaqSupportLink
        onClick={() => {
          GaEvents[GaEventCategory.REVIEW_SCREEN].supportLink();
        }}
      />
      <ErrorModal
        open={openErrorModal}
        handleClose={() => setOpenErrorModal(false)}
        message={message}
      />
    </>
  );
}
