import ReactGA from 'react-ga4';

export enum GaEventCategory {
  LANDING_PAGE = 'landing_page',
  SIGNUP_PAGE = 'signup_page',
  ACCOUNT_PAGE = 'account_page',
  REVIEW_SCREEN = 'review_screen',
  SONG_SELECTION_SCREEN = 'song_selection_screen',
  CALCULATION_SCREEN = 'calculation_screen',
  REPORT_UPLOAD_SCREEN = 'report_upload_screen',
  DASHBOARD = 'DASHBOARD',
}

interface GaEvent {
  [eventName: string]: () => void;
}

interface IGaEvents {
  [category: string]: GaEvent;
}

export const GaEvents: IGaEvents = {
  [GaEventCategory.LANDING_PAGE]: {
    clickedStartToday: () => {
      ReactGA.event({
        category: GaEventCategory.LANDING_PAGE,
        action: 'landingpage_clicked_start_today',
        label: 'Clicked on the "Start today" button from Landing Page',
      });
    },
    formInteraction: () => {
      ReactGA.event({
        category: GaEventCategory.LANDING_PAGE,
        action: 'landingpage_form_interaction',
        label: 'User has interacted with either of the input fields (Artist ID, name)',
      });
    },
    topAuthLink: () => {
      ReactGA.event({
        category: GaEventCategory.LANDING_PAGE,
        action: 'landingpage_auth_link_top_right',
        label: 'User has clicked "signup / login" in top right',
      });
    },
    myDealsLink: () => {
      ReactGA.event({
        category: GaEventCategory.LANDING_PAGE,
        action: 'landingpage_my_deals_top_right',
        label: 'User has clicked “My deals” in top right',
      });
    },
  },
  [GaEventCategory.SIGNUP_PAGE]: {
    fieldInteraction: () => {
      ReactGA.event({
        category: GaEventCategory.SIGNUP_PAGE,
        action: 'signup_field_interaction',
        label: 'User has interacted with input fields',
      });
    },
  },
  [GaEventCategory.ACCOUNT_PAGE]: {
    accountCreated: () => {
      ReactGA.event({
        category: GaEventCategory.ACCOUNT_PAGE,
        action: 'account_created',
        label: 'Account is created',
      });
    },
    emailConfirmed: () => {
      ReactGA.event({
        category: GaEventCategory.ACCOUNT_PAGE,
        action: 'email_confirmed',
        label: 'User confirmed email',
      });
    },
  },
  [GaEventCategory.REVIEW_SCREEN]: {
    viewed: () => {
      ReactGA.event({
        category: GaEventCategory.REVIEW_SCREEN,
        action: 'review_screen_view',
        label: 'User visiting review screen',
      });
    },
    supportLink: () => {
      ReactGA.event({
        category: GaEventCategory.REVIEW_SCREEN,
        action: 'review_screen_faq_support',
        label: 'User has clicked FAQ and support',
      });
    },
    estimatedRevenueChanged: () => {
      ReactGA.event({
        category: GaEventCategory.REVIEW_SCREEN,
        action: 'review_screen_custom_estimation',
        label: 'User has inputted their revenue estimation',
      });
    },
    confirmed: () => {
      ReactGA.event({
        category: GaEventCategory.REVIEW_SCREEN,
        action: 'review_screen_confirmed',
        label: 'User has clicked confirm',
      });
    },
  },
  [GaEventCategory.SONG_SELECTION_SCREEN]: {
    viewed: () => {
      ReactGA.event({
        category: GaEventCategory.SONG_SELECTION_SCREEN,
        action: 'selection_screen_view',
        label: 'User visited song selection screen',
      });
    },
    supportLink: () => {
      ReactGA.event({
        category: GaEventCategory.SONG_SELECTION_SCREEN,
        action: 'selection_screen_faq_support',
        label: 'User has clicked FAQ and support',
      });
    },
    selectedEntireCatalog: () => {
      ReactGA.event({
        category: GaEventCategory.SONG_SELECTION_SCREEN,
        action: 'selection_screen_sell_entire_catalog',
        label: 'User clicks sell entire catalog',
      });
    },
    addTrack: () => {
      ReactGA.event({
        category: GaEventCategory.SONG_SELECTION_SCREEN,
        action: 'selection_screen_add_track',
        label: 'User clicks add track',
      });
    },
    minTrackNotSelected: () => {
      ReactGA.event({
        category: GaEventCategory.SONG_SELECTION_SCREEN,
        action: 'selection_screen_not_enough_tracks',
        label: 'User has not selected enough tracks',
      });
    },
    confirmed: () => {
      ReactGA.event({
        category: GaEventCategory.SONG_SELECTION_SCREEN,
        action: 'selection_screen_confirmed',
        label: 'User has clicked confirm and moves to next step',
      });
    },
  },
  [GaEventCategory.CALCULATION_SCREEN]: {
    viewed: () => {
      ReactGA.event({
        category: GaEventCategory.CALCULATION_SCREEN,
        action: 'calculate_screen_view',
        label: 'User visited calculation screen',
      });
    },
    supportLink: () => {
      ReactGA.event({
        category: GaEventCategory.CALCULATION_SCREEN,
        action: 'calculate_screen_faq_support',
        label: 'User has clicked FAQ and support',
      });
    },
    modifyDealLength: () => {
      ReactGA.event({
        category: GaEventCategory.CALCULATION_SCREEN,
        action: 'calculate_screen_change_of_deal_length',
        label: 'User has changed the deal length',
      });
    },
    editTracks: () => {
      ReactGA.event({
        category: GaEventCategory.CALCULATION_SCREEN,
        action: 'calculate_screen_edit_tracks',
        label: 'User has clicked edit tracks',
      });
    },
    confirmed: () => {
      ReactGA.event({
        category: GaEventCategory.CALCULATION_SCREEN,
        action: 'calculate_screen_confirmed',
        label: 'User has clicked next',
      });
    },
  },
  [GaEventCategory.REPORT_UPLOAD_SCREEN]: {
    viewed: () => {
      ReactGA.event({
        category: GaEventCategory.REPORT_UPLOAD_SCREEN,
        action: 'upload_screen_view',
        label: 'User visited upload report screen',
      });
    },
    supportLink: () => {
      ReactGA.event({
        category: GaEventCategory.REPORT_UPLOAD_SCREEN,
        action: 'upload_screen_faq_support',
        label: 'User has clicked FAQ and support',
      });
    },
    selectDistributor: () => {
      ReactGA.event({
        category: GaEventCategory.REPORT_UPLOAD_SCREEN,
        action: 'upload_screen_select_distributor',
        label: 'User has clicked on a distributor from the dropdown',
      });
    },
    reportsGuide: () => {
      ReactGA.event({
        category: GaEventCategory.REPORT_UPLOAD_SCREEN,
        action: 'upload_screen_reports_guide',
        label: 'User has clicked on how to get reports',
      });
    },
    fileUploaded: () => {
      ReactGA.event({
        category: GaEventCategory.REPORT_UPLOAD_SCREEN,
        action: 'upload_screen_file_uploaded',
        label: 'User has uploaded files',
      });
    },
    noFilesUploaded: () => {
      ReactGA.event({
        category: GaEventCategory.REPORT_UPLOAD_SCREEN,
        action: 'upload_screen_no_files',
        label: 'User has not uploaded any files',
      });
    },
    confirmed: () => {
      ReactGA.event({
        category: GaEventCategory.CALCULATION_SCREEN,
        action: 'upload_screen_confirmed',
        label: 'User has clicked submit and moves on',
      });
    },
  },
  [GaEventCategory.DASHBOARD]: {
    exploreDeal: () => {
      ReactGA.event({
        category: GaEventCategory.DASHBOARD,
        action: 'dashboard_existing_deal',
        label: 'User clicks continue on an existing deal',
      });
    },
  },
};
