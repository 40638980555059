import { colors } from 'styles/colors';
import { useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import { fontStyles } from 'styles/font-styles';
import { Grid, Typography } from '@mui/material';
import DialogModal from 'components/dialog-modal';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { GaEvents, GaEventCategory } from 'utils/gaEvents';

const StyledLink = styled('div')(({ theme }) => ({
  marginTop: '24px',
  ...theme.fontStyles.subtext,
  color: theme.colors.text.light.body,
  textDecoration: 'underline',
  cursor: 'pointer',
  textAlign: 'center',
}));

const linkList = [
  {
    title: 'TuneCore',
    link: 'https://drive.google.com/file/d/1fRgwTKt5uMLfVKOw-Ccu1XPieRKbKqQy/view?usp=sharing',
  },
  {
    title: 'Ditto',
    link: 'https://drive.google.com/file/d/1Is7ocM3SX1L0u1G6rOb0djwY2IVwBAH0/view?usp=sharing',
  },
  {
    title: 'DistroKid',
    link: 'https://drive.google.com/file/d/11BOwlkwUL36wp5Atc8_c8i1UM3RjaZgC/view?usp=sharing',
  },
  {
    title: 'CDBaby',
    link: 'https://drive.google.com/file/d/1SpqQN4A8sKTtbbnnjnhHWoVP9uxvR7Un/view?usp=sharing',
  },
];

function ReportInstructions() {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <>
      <StyledLink
        onClick={() => {
          GaEvents[GaEventCategory.REPORT_UPLOAD_SCREEN].reportsGuide();
          handleOpenModal();
        }}
      >
        How to get reports
      </StyledLink>
      <DialogModal withActions open={openModal} handleClose={handleCloseModal}>
        <Grid container justifyContent="center">
          <Typography
            sx={{
              marginBottom: '8px',
              fontSize: '32px',
              lineHeight: '40px',
              color: colors.text.light.title,
            }}
          >
            How to get report
          </Typography>
        </Grid>
        <Grid container justifyContent="center">
          <Typography
            sx={{
              fontSize: '18px',
              lineHeight: '26px',
              fontWeight: 'lighter',
              color: colors.text.light.label,
              textAlign: 'center',
            }}
          >
            Please check the distributor list below and download instruction
          </Typography>
        </Grid>
        <Grid
          container
          flexDirection="column"
          sx={{
            padding: { xs: '24px 24px 0', sm: '24px 56px 0' },
            ...fontStyles.bodyLight,
            color: colors.text.dark.body,
          }}
        >
          {linkList.map((item) => (
            <Grid container key={item.title} flexWrap="nowrap" alignItems="center">
              <Grid sx={{ marginTop: '5px' }}>
                <DownloadIcon />
              </Grid>
              <Grid>
                <a
                  target="__blank"
                  href={item.link}
                  style={{ color: colors.text.light.body, marginLeft: '10px' }}
                >
                  {item.title}
                </a>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </DialogModal>
    </>
  );
}

export default ReportInstructions;
