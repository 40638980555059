import React, { useState, useEffect } from 'react';
import Text from 'components/text';
import BaseInput from 'components/inputs/base-input';
import { Box, Grid, MenuItem, Typography, CircularProgress } from '@mui/material';
import api from 'utils/api';
import TinySpinner from 'components/tiny-spinner';
import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as Uploaded } from 'assets/icons/uploaded.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';

import ReportInstructions from 'pages/newdeal/components/ReportInstructions';
import SubmitButton from '../SubmitButton';
import Footer from '../Footer';
import { useError } from 'hooks/useError';
import ErrorModal from 'components/error-modal';
import { getIsAllowedExtensions } from 'utils/helpers';

import FaqSupportLink from '../FaqSupportLink';
import { GaEvents, GaEventCategory } from 'utils/gaEvents';

const REPORT_STATUS = {
  LOADING: 'loading',
  UPLOADED: 'uploaded',
  DRAFT: 'draft',
};

const ALLOWED_EXTENSIONS = ['txt', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'tsv'];

export default function UploadReport(props: any) {
  const { store } = props;
  const { message, setError } = useError();
  const [loading, setLoading] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const {
    state,
    setCurrentStepNumber,
    setReportStatus,
    removeReport,
    addReport,
    replaceTempReport,
  } = store;
  const { currentStepNumber, deal } = state;
  const { reports } = deal;
  const hasOneOrManyReports = (reports || []).length > 0;

  useEffect(() => {
    GaEvents[GaEventCategory.REPORT_UPLOAD_SCREEN].viewed();
  }, []);

  async function handleSubmit(event: any) {
    event.preventDefault();
    if (deal.reports.length < 1) {
      GaEvents[GaEventCategory.REPORT_UPLOAD_SCREEN].noFilesUploaded();
      setError('Please upload your report before submitting the deal');
      setOpenErrorModal(true);
    } else {
      setError('');
      setOpenErrorModal(false);
      try {
        setLoading(true);
        await api.deals.dealSubmit({ id: deal.id });
        setLoading(false);
        GaEvents[GaEventCategory.REPORT_UPLOAD_SCREEN].confirmed();
        setCurrentStepNumber(currentStepNumber + 1);
      } catch (error) {
        setError(
          'Something went wrong while submitting the deal. Please refresh this page and try again.'
        );
        setLoading(false);
        setOpenErrorModal(true);
      }
    }
  }

  return (
    <Box paddingBottom="80px">
      <Box
        paddingTop={{
          xs: '12px',
          sm: '22px',
        }}
        paddingBottom="32px"
        maxWidth="500px"
        marginX="auto"
      >
        <Text textAlign="center" fontSize="32px" lineHeight="40px" fontWeight="bold">
          Upload reports
        </Text>
        <Text textAlign="center" fontSize="18px" lineHeight="26px" marginTop="8px" color="#484848">
          Upload the last six months of detailed reports covering all tracks you want included in
          your advance offer.
        </Text>
      </Box>

      {hasOneOrManyReports ? (
        <Grid container>
          <Grid item xs={12} md={4} lg={5}>
            <FileUploader
              state={state}
              setError={setError}
              addReport={addReport}
              replaceTempReport={replaceTempReport}
              setReportStatus={setReportStatus}
              setOpenErrorModal={setOpenErrorModal}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            lg={7}
            paddingLeft={{
              xs: '0px',
              md: '40px',
            }}
            paddingTop={{
              xs: '30px',
              md: '0px',
            }}
          >
            <RenderFiles
              deal={deal}
              removeReport={removeReport}
              setReportStatus={setReportStatus}
              setError={setError}
              setOpenErrorModal={setOpenErrorModal}
            />
          </Grid>
        </Grid>
      ) : (
        <Box
          paddingTop={{
            xs: '12px',
            sm: '22px',
          }}
          paddingBottom="32px"
          maxWidth="500px"
          marginX="auto"
        >
          <FileUploader
            state={state}
            setError={setError}
            addReport={addReport}
            replaceTempReport={replaceTempReport}
            setReportStatus={setReportStatus}
            setOpenErrorModal={setOpenErrorModal}
          />
        </Box>
      )}

      <Footer currentStepNumber={currentStepNumber} setCurrentStepNumber={setCurrentStepNumber}>
        <SubmitButton type="submit" onClick={handleSubmit}>
          {loading ? (
            <Box minWidth="120px" paddingTop="7px">
              <CircularProgress sx={{ color: '#ddd' }} size="24px" />
            </Box>
          ) : (
            <Text fontSize="20px" fontWeight="bold" color="#fff" paddingX="24px" paddingY="9px">
              Submit
            </Text>
          )}
        </SubmitButton>
      </Footer>
      <ErrorModal
        open={openErrorModal}
        handleClose={() => setOpenErrorModal(false)}
        message={message}
      />
      <FaqSupportLink
        onClick={() => {
          GaEvents[GaEventCategory.REPORT_UPLOAD_SCREEN].supportLink();
        }}
      />
    </Box>
  );
}

function FileUploader(props: any) {
  const { state, setError, addReport, setReportStatus, setOpenErrorModal, replaceTempReport } =
    props;
  const { deal } = state;
  const [distributorName, setDistributorName] = useState(false);

  const [dragActive, setDragActive] = useState(false);
  const inputRef = React.useRef(null);

  const distributors = [
    { name: 'tunecore', label: 'TuneCore' },
    { name: 'ditto', label: 'Ditto' },
    { name: 'distrokid', label: 'DistroKid' },
    { name: 'cdbaby', label: 'CD Baby' },
    { name: 'other', label: 'Other' },
  ];

  const checkExtensions = (files: any) => {
    const fileNames = [];
    for (const file of files) {
      fileNames.push(file.name);
    }
    const extensionsAreValid = fileNames.every((file: any) =>
      getIsAllowedExtensions(file, ALLOWED_EXTENSIONS)
    );
    return extensionsAreValid;
  };

  const uploadFiles = async (files: any) => {
    if (checkExtensions(files)) {
      for (const file of files) {
        const formData = new FormData();
        formData.append('file', file);

        // Create a temporary report file.
        const tempId = uuidv4();
        let report: any = {
          id: tempId,
          name: file.name,
          distributor: distributorName,
          status: REPORT_STATUS.LOADING,
          deal_id: deal.id,
          hubspotFileUrl: '#',
          hubspotFileId: 0,
          createdAt: Date.now(),
          url: null,
        };
        // console.log('temp report:', report);
        addReport(report);

        try {
          report = await api.reports.createInitialReport({
            id: deal.id,
            data: formData,
          });

          replaceTempReport({
            report: {
              ...report,
              distributor: distributorName,
              status: REPORT_STATUS.LOADING,
            },
            tempId,
          });
        } catch (e) {
          console.log(e);
          setError(
            'Something went wrong while processing the report. Please refresh this page and try again.'
          );
          setOpenErrorModal(true);
        }

        if (report !== null) {
          try {
            await api.reports.finaliseReport({
              data: { deal_id: deal.id, report_id: report.id, distributor: distributorName },
            });
            setReportStatus(report.id, REPORT_STATUS.UPLOADED);
            GaEvents[GaEventCategory.REPORT_UPLOAD_SCREEN].fileUploaded();
          } catch (e) {
            setError(
              'Something went wrong while updating the report. Please refresh this page and try again.'
            );
            setOpenErrorModal(true);
            setReportStatus(report.id, REPORT_STATUS.DRAFT);
          }
        }
      }
    } else {
      setError(
        `Uploaded file type is not supported. Supported file types are: ${ALLOWED_EXTENSIONS.join(
          ', '
        )}`
      );
      setOpenErrorModal(true);
    }
  };

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      uploadFiles(e.dataTransfer.files);
    }
  };

  const handleChange = function (e: any) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      uploadFiles(e.target.files);
    }
  };

  const onButtonClick = () => {
    // @ts-ignore
    inputRef.current.click();
  };

  return (
    <Box>
      <BaseInput
        select
        variant="filled"
        name="distributor"
        label="Reports source"
        defaultValue="placeholder"
        SelectProps={{ displayEmpty: true }}
        sx={{ width: '100%' }}
        onChange={(event: any) => {
          GaEvents[GaEventCategory.REPORT_UPLOAD_SCREEN].selectDistributor();
          setDistributorName(event.target.value);
        }}
        error={false}
      >
        <MenuItem disabled value="placeholder">
          <Typography sx={{ color: '#444' }}>Select distributor</Typography>
        </MenuItem>
        {distributors.map((dist) => (
          <MenuItem key={dist.label} value={dist.name}>
            {dist.label}
          </MenuItem>
        ))}
      </BaseInput>

      {distributorName && (
        <Box
          onDragEnter={handleDrag}
          position="relative"
          border={2}
          borderColor="#DFE1E6"
          marginTop="24px"
          paddingY="50px"
          borderRadius="4px"
          sx={{
            borderStyle: 'dashed',
          }}
        >
          <Box
            display="none"
            component="input"
            ref={inputRef}
            type="file"
            multiple={true}
            key={Date.now()}
            onChange={handleChange}
          />
          <Box component="label">
            <Box textAlign="center">
              <Box
                component="button"
                className="upload-button"
                onClick={onButtonClick}
                paddingY="5px"
                paddingX="24px"
                bgcolor="#000"
                borderRadius="4px"
                border="none"
                marginBottom="8px"
              >
                <Text fontSize="20px" lineHeight="30px" color="#fff">
                  Upload files
                </Text>
              </Box>
              <Text fontSize="18px" color="#818181">
                Or drag them in
              </Text>
            </Box>
          </Box>
          {dragActive && (
            <Box
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
              position="absolute"
              bgcolor="#ccc"
              top={0}
              bottom={0}
              right={0}
              left={0}
              sx={{
                opacity: 0.3,
              }}
            ></Box>
          )}
        </Box>
      )}

      <ReportInstructions />
    </Box>
  );
}

function RenderFiles(props: any) {
  const { deal, removeReport, setReportStatus, setError, setOpenErrorModal } = props;
  const { reports } = deal;

  const handleReportFileDelete = async (report: any) => {
    setReportStatus(report.id, REPORT_STATUS.LOADING);
    try {
      await api.reports.remove({ id: report.id });
      removeReport(report.id);
    } catch (e) {
      setError(
        'Something went wrong while deleting the report file. Please refresh this page and try again.'
      );
      setOpenErrorModal(true);
      setReportStatus(report.id, REPORT_STATUS.DRAFT);
    }
  };

  return (
    <Box
      bgcolor="#FAFBFC"
      border={1}
      borderColor="#DFE1E6"
      borderRadius="8px"
      padding="24px"
      marginBottom="60px"
    >
      <Grid container paddingBottom="16px" borderBottom={1} borderColor="#DFE1E6">
        <Grid item xs={9} md={6}>
          <Text color="#818181" fontSize="18px">
            Name
          </Text>
        </Grid>
        <Grid
          display={{
            xs: 'none',
            md: 'block',
          }}
          item
          xs={0}
          md={4}
        >
          <Text color="#818181" fontSize="18px">
            Distributor
          </Text>
        </Grid>
        <Grid item xs={3} md={2}></Grid>
      </Grid>

      {reports.map((report: any) => {
        return (
          <Grid container paddingY="16px" key={report.id}>
            <Grid item xs={9} md={6}>
              <Box display="flex">
                {report.status === 'draft' && <Warning title="Report file was not uploaded" />}
                {report.status === 'uploaded' && <Uploaded />}
                {report.status === 'loading' && <TinySpinner />}
                <Text
                  color="#333333"
                  fontSize="18px"
                  marginLeft="16px"
                  width="100%"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  marginRight="16px"
                  title={report.name}
                >
                  {report.name}
                </Text>
              </Box>
            </Grid>
            <Grid
              display={{
                xs: 'none',
                md: 'block',
              }}
              item
              xs={0}
              md={4}
            >
              <Text color="#333333" fontSize="18px">
                {report.distributor}
              </Text>
            </Grid>
            <Grid item xs={3} md={2}>
              {report.status === 'uploaded' && (
                <Text
                  color="#333333"
                  fontSize="18px"
                  textAlign="right"
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  title={`Delete "${report.name}" report`}
                  onClick={() => handleReportFileDelete(report)}
                >
                  Delete
                </Text>
              )}
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
}
