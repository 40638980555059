import React, { memo } from 'react';
// mui components
import { Grid, Autocomplete, Box, createFilterOptions, CircularProgress } from '@mui/material';
// components
import BaseInput from './base-input';
// styles
import { fontStyles } from 'styles/font-styles';
import { colors } from 'styles/colors';

interface IOption {
  label: string;
  value: string | number;
}

const filterValueOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: IOption) => option.value.toString(),
});

const filterLabelOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: IOption) => option.label,
});

interface OwnProps {
  options: {
    label: string;
    value: string | number;
  }[];
  name: string;
  label: string;
  error: boolean;
  dark?: boolean;
  helperText?: string;
  defaultValue?: string | number;
  loading?: boolean;
  onChange: (...event: any[]) => void;
  additionalOnChange?: (value: string | number) => void;
  handleChange?: (value: string) => void;
}

const AutocompleteInput: React.FC<OwnProps> = ({
  options,
  name,
  label,
  error,
  dark = false,
  helperText,
  onChange,
  additionalOnChange,
  handleChange,
  ...props
}) => {
  return (
    <Grid
      container
      sx={{
        '& li[aria-selected="true"]': {
          backgroundColor: `${colors.main.purple} !important`,
        },
        '& ul': {
          '& .Mui-focused': {
            backgroundColor: `${colors.main.purple} !important`,
          },
        },
        '& .MuiPaper-root': {
          backgroundColor: colors.main.blackLight,
        },
        '& .MuiAutocomplete-clearIndicator': {
          color: colors.text.dark.label,
        },
        '& .MuiAutocomplete-loading': {
          ...fontStyles.placeholder,
          fontWeight: 'normal',
          color: colors.text.dark.label,
        },
      }}
    >
      <Autocomplete
        {...props}
        freeSolo
        fullWidth
        // @ts-ignore
        options={options}
        // @ts-ignore
        filterOptions={(option: IOption[], state) => {
          if (option.every(({ value }) => value === state.inputValue)) {
            // @ts-ignore
            return filterValueOptions(option, state);
          }
          // @ts-ignore
          return filterLabelOptions(option, state);
        }}
        onChange={(_e, data) => {
          // @ts-ignore
          if (typeof data !== 'string' && data?.value) {
            // @ts-ignore
            onChange(data.value);
            // @ts-ignore
            additionalOnChange && additionalOnChange(data.value);
          } else {
            onChange(data);
          }
        }}
        renderInput={(params) => {
          return (
            <BaseInput
              {...params}
              name={name}
              dark={dark}
              label={label}
              error={error}
              variant="filled"
              helperText={helperText}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {props.loading ? (
                      <Box sx={{ display: 'flex', transform: 'translateY(-50%)' }}>
                        <CircularProgress size={20} sx={{ color: colors.main.purple }} />
                      </Box>
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              onChange={(event) => {
                onChange(null);
                handleChange && handleChange(event.target.value);
              }}
            />
          );
        }}
        renderOption={(optionProps, option) => {
          // @ts-ignore
          const optionLabel: any = option.label || option;

          return (
            <Box
              component="li"
              {...optionProps}
              sx={{
                height: '52px',
                backgroundColor: colors.main.blackLight,
                ...fontStyles.placeholder,
                fontWeight: 'normal',
                color: colors.text.dark.label,
                '&:hover': {
                  backgroundColor: `${colors.main.purple} !important`,
                },
              }}
            >
              {optionLabel}
            </Box>
          );
        }}
        ListboxComponent={(listProps) => (
          <Grid {...listProps} sx={{ padding: 'unset !important' }} />
        )}
      />
    </Grid>
  );
};

export default memo(AutocompleteInput);
