import React, { useCallback, useState } from 'react';
// mui component
import { Grid, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import CreateDealModal from './components/create-deal-modal';
// components
import DealItem from '../../components/deal-item';
// custom hooks
import { useGetDealList } from 'hooks/swrRequests';
// styles
import { fontStyles } from 'styles/font-styles';
import { colors } from 'styles/colors';

const StyledButton = styled(Button)(({ theme }) => ({
  width: '174px',
  height: '48px',
  backgroundColor: theme.colors.main.purple,
  ...theme.fontStyles.body,
  color: theme.colors.main.white,
  textTransform: 'unset',
  '&:hover': {
    backgroundColor: theme.colors.main.purpleDark,
  },
}));

const DealsSection: React.FC = () => {
  const { data: deals } = useGetDealList();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <Grid
      container
      flexDirection="column"
      sx={{ maxWidth: '842px', margin: { xs: '16px', md: '40px' } }}
    >
      <Grid container justifyContent="space-between">
        <Grid container item xs={12} sm={6} sx={{ marginBottom: { xs: '10px', md: 0 } }}>
          <Typography sx={{ ...fontStyles.h3, color: colors.text.light.title }}>
            Your deals
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={6}
          sx={{ justifyContent: { xs: 'flex-start', sm: 'flex-end' } }}
        >
          <StyledButton variant="contained" onClick={handleOpenModal}>
            Create new deal
          </StyledButton>
        </Grid>
      </Grid>
      <Grid container flexDirection="column" sx={{ marginTop: '40px' }}>
        {deals?.map((item) => (
          <DealItem key={item.id} {...item} />
        ))}
      </Grid>
      <CreateDealModal open={openModal} handleClose={handleCloseModal} />
    </Grid>
  );
};

export default DealsSection;
