import { Box, LinearProgress, linearProgressClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IProps {
  value: number;
}

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '4px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'inherit',
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.colors.main.purple,
  },
}));

export default function Progress(props: IProps) {
  const { value } = props;

  return (
    <Box
      sx={{ width: '100%', position: 'static', top: '72px', left: 0 }}
      display={{
        xs: 'none',
        sm: 'none',
        md: 'block',
      }}
    >
      <StyledLinearProgress variant="determinate" value={value} />
    </Box>
  );
}
