import React from 'react';

import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Text from 'components/text';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  paddingTop: '10px',
  paddingBottom: '10px',
  backgroundColor: '#14161B',
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <Box
        sx={{
          '& path': {
            fill: '#9FA5AE',
          },
        }}
      >
        <PlusIcon />
      </Box>
    }
    {...props}
  />
))(() => ({
  padding: 0,
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
  paddingBottom: '12px',
  backgroundColor: '#14161B',
}));

const questions = [
  {
    title: 'How does it work?',
    content:
      'We estimate how much your catalog will earn over the next 1-3 years. You decide how long and how much of your catalog to include in the deal. After the term, 100% of the rights revert to you.',
  },
  {
    title: 'What royalties does the deal cover?',
    content:
      'Only your streaming income. Everything else - touring, publishing, and physical sales like merch and vinyl - remains untouched.',
  },
  {
    title: 'How does this affect my upcoming releases?',
    content: 'It doesn’t. FineArt only gives advances on your catalog.',
  },
  {
    title: 'Can I stay with my distributor?',
    content: 'Yes. Next question!',
  },
  {
    title: 'Actually, I want to switch distributors. Can you help with that?',
    content:
      'Yes. Distribution is important, and we can make sure your catalog is in the best possible place.',
  },
];

export default function FaqSection() {
  const [expanded, setExpanded] = React.useState<string | false>('');

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box
      width="100%"
      paddingTop={{
        xs: '100px',
        md: '150px',
      }}
      paddingBottom={{
        xs: '140px',
        md: '250px',
      }}
    >
      <Box textAlign="center" marginBottom="50px">
        <Text
          color="#DADADA"
          beniRegular
          letterSpacing="1.2px"
          fontSize={{
            xs: '54px',
            sm: '64px',
          }}
        >
          Frequently Asked Questions
        </Text>
      </Box>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={10} md={8}>
          <Box marginX="20px">
            {questions.map((question, index) => (
              <Accordion
                key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
                sx={{
                  borderBottom: `1px solid #282828`,
                }}
              >
                <AccordionSummary
                  aria-controls={`panel${index}d-content`}
                  id={`panel${index}d-header`}
                >
                  <Text
                    color="#9FA5AE"
                    fontSize="24px"
                    textTransform="uppercase"
                    letterSpacing={1.2}
                    lineHeight="32px"
                  >
                    {question.title}
                  </Text>
                </AccordionSummary>
                <AccordionDetails>
                  <Text color="#9FA5AE" fontSize="22px" lineHeight="26px">
                    {question.content}
                  </Text>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
