import React, { useCallback, useState, useEffect } from 'react';
import * as yup from 'yup';
import api from 'utils/api';
import Footer from '../Footer';
import Text from 'components/text';
import Switch from 'components/switch';
import Slider from 'components/slider';
import { useWidth } from 'hooks/useWidth';
import Checkbox from 'components/checkbox';
import SubmitButton from '../SubmitButton';
import FaqSupportLink from '../FaqSupportLink';
import FlatDesignError from 'components/flat-design-error';
import { GaEvents, GaEventCategory } from 'utils/gaEvents';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { Box, Grid, Button, CircularProgress } from '@mui/material';
import TransformTracks from 'pages/newdeal/helpers/transformTracks';
import AddTrackModal from 'pages/newdeal/components/add-track-modal';
import { ReactComponent as MusicIcon } from 'assets/icons/music.svg';

import { useError } from 'hooks/useError';
import ErrorModal from 'components/error-modal';

import { IDealStore } from '../../store';

export const ValidationSchema = yup.object({
  ownEstimation: yup.number().typeError('Error message'),
});

interface IProps {
  store: IDealStore;
}

export default function SelectSongs(props: IProps) {
  const { store } = props;
  const { isMobileOrTablet } = useWidth();

  const {
    state,
    setCurrentStepNumber,
    toggleFullCatalogue,
    setTrackOwnership,
    toggleTrack,
    selectAllTracks,
    addTrack,
  } = store;

  const { currentStepNumber, tracks, isFullCatalogue, allTracksSelected, deal } = state;
  const totalSelectedTracks = tracks.filter((track: any) => track.selected).length;
  const [loading, setLoading] = useState(false);
  const artistSpotifyId = deal.artistSpotifyId;
  const [showMinimumTrackLimitError, setMinimumTrackLimitError] = useState(false);

  const { message, setError } = useError();
  const [openErrorModal, setOpenErrorModal] = useState(false);

  useEffect(() => {
    GaEvents[GaEventCategory.SONG_SELECTION_SCREEN].viewed();
  }, []);

  useEffect(() => {
    if (totalSelectedTracks >= 3 && showMinimumTrackLimitError) {
      setMinimumTrackLimitError(false);
    }
    // eslint-disable-next-line
  }, [totalSelectedTracks]);

  const onSubmit = useCallback(
    async (event: any) => {
      event.preventDefault();
      if (totalSelectedTracks < 3) {
        GaEvents[GaEventCategory.SONG_SELECTION_SCREEN].minTrackNotSelected();
        setMinimumTrackLimitError(true);
      } else {
        setLoading(true);
        setMinimumTrackLimitError(false);
        try {
          await api.deals.updateDeal({
            id: deal.id,
            data: {
              tracks: TransformTracks(tracks, deal.artistSpotifyId),
              isEntireCatalogue: isFullCatalogue,
            },
          });
          GaEvents[GaEventCategory.SONG_SELECTION_SCREEN].confirmed();
          setCurrentStepNumber(3);
        } catch (err) {
          setError(
            'Something went wrong while updating the list of songs. Please refresh this page and try again.'
          );
          setOpenErrorModal(true);
        } finally {
          setLoading(false);
        }
      }
    },
    // eslint-disable-next-line
    [totalSelectedTracks, tracks, isFullCatalogue]
  );

  const handleSelectCatalogue = () => {
    if (!isFullCatalogue) {
      GaEvents[GaEventCategory.SONG_SELECTION_SCREEN].selectedEntireCatalog();
    }
    toggleFullCatalogue(!isFullCatalogue);
  };

  return (
    <Box
      paddingTop={{
        xs: '12px',
        sm: '22px',
      }}
      paddingBottom="120px"
    >
      <Text textAlign="center" fontSize="32px" lineHeight="40px" fontWeight="bold">
        Select songs
      </Text>
      <Text textAlign="center" fontSize="18px" lineHeight="26px" marginTop="8px">
        Select the tracks you want to include in the deal
      </Text>

      <Box display="flex" gap="12px" justifyContent="flex-end" marginTop="40px">
        <Text color="#333333">Sell entire catalogue</Text>
        <Switch
          checked={isFullCatalogue}
          onChange={handleSelectCatalogue}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Box>
      {isFullCatalogue ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop="24px"
          border={1}
          borderColor="#DFE1E6"
          borderRadius="8px"
          padding="24px"
          sx={{
            '& path': {
              fill: '#7801EF',
            },
          }}
        >
          <Text fontSize="20px" lineHeight="30px" marginRight="16px">
            Great! Let’s see your estimated advance
          </Text>{' '}
          <MusicIcon />
        </Box>
      ) : (
        <>
          {!isMobileOrTablet ? (
            <Grid
              container
              flexDirection="column"
              alignItems="center"
              marginTop="24px"
              border={1}
              borderColor="#DFE1E6"
              borderRadius="8px"
              padding="24px"
            >
              <Grid
                container
                paddingBottom="16px"
                borderBottom={1}
                borderColor="#DFE1E6"
                marginBottom="24px"
              >
                <THeadCell label="Top 10 tracks" colums={4} />
                <THeadCell label="Date released" colums={2} />
                <THeadCell label="" colums={1} />
                <THeadCell label="% Owned by Artist" colums={4} />
                <THeadCell colums={1} alignRight={true}>
                  <Box marginRight="-9px" marginBottom="-9px">
                    <Checkbox
                      checked={allTracksSelected}
                      onChange={() => {
                        selectAllTracks();
                      }}
                    />
                  </Box>
                </THeadCell>
              </Grid>

              {tracks.map(({ id, name, date_released, ownership, selected }: any) => (
                <Grid container marginBottom="40px" key={id}>
                  <TBodyCell colums={4} label={name} />
                  <TBodyCell colums={2} label={date_released} />
                  <TBodyCell colums={1} label={`${ownership}%`} />
                  <TBodyCell colums={4} label="">
                    <Slider
                      step={1}
                      min={0}
                      max={100}
                      value={ownership}
                      defaultValue={ownership}
                      onChange={(event, value) => {
                        setTrackOwnership(id, value);
                      }}
                    />
                  </TBodyCell>
                  <TBodyCell colums={1} label="" alignRight={true}>
                    <Box marginRight="-9px" marginBottom="-9px">
                      <Checkbox
                        checked={selected}
                        onChange={() => {
                          toggleTrack(id);
                        }}
                      />
                    </Box>
                  </TBodyCell>
                </Grid>
              ))}
              <AddNewTrack tracks={tracks} artistSpotifyId={artistSpotifyId} addTrack={addTrack} />
            </Grid>
          ) : (
            <Box
              border={1}
              marginTop="24px"
              padding="16px"
              borderRadius="8px"
              position="relative"
              borderColor="#DFE1E6"
              display={{
                sm: 'block',
                md: 'none',
              }}
            >
              {tracks.map((track: any) => (
                <MobileCell
                  key={track.id}
                  track={track}
                  toggleTrack={toggleTrack}
                  setTrackOwnership={setTrackOwnership}
                />
              ))}
              <AddNewTrack tracks={tracks} artistSpotifyId={artistSpotifyId} addTrack={addTrack} />
            </Box>
          )}
        </>
      )}

      <Footer currentStepNumber={currentStepNumber} setCurrentStepNumber={setCurrentStepNumber}>
        <Box display="flex" flexGrow={1} justifyContent="center">
          <Text fontSize="20px">
            Selected:{' '}
            <Box component="span" color="#5800AF">
              {totalSelectedTracks} track{totalSelectedTracks > 1 ? 's' : ''}
            </Box>
          </Text>
        </Box>
        <SubmitButton type="submit" disabled={loading} onClick={onSubmit}>
          {loading ? (
            <Box minWidth="120px" paddingTop="7px">
              <CircularProgress sx={{ color: '#ddd' }} size="24px" />
            </Box>
          ) : (
            <Text fontSize="20px" fontWeight="bold" color="#fff" paddingX="24px" paddingY="9px">
              Next
            </Text>
          )}
        </SubmitButton>
      </Footer>
      {showMinimumTrackLimitError ? (
        <FlatDesignError
          message="Please select at least 3 tracks"
          onClose={() => {
            setMinimumTrackLimitError(false);
          }}
        />
      ) : null}
      <FaqSupportLink
        onClick={() => {
          GaEvents[GaEventCategory.SONG_SELECTION_SCREEN].supportLink();
        }}
      />
      <ErrorModal
        open={openErrorModal}
        handleClose={() => setOpenErrorModal(false)}
        message={message}
      />
    </Box>
  );
}

interface ITHeadCell {
  label?: string;
  colums: number;
  alignRight?: boolean;
  children?: React.ReactNode;
}
function THeadCell(props: ITHeadCell) {
  const { label = '', colums, children, alignRight = false } = props;
  return (
    <Grid
      item
      display="flex"
      justifyContent={alignRight ? 'flex-end' : 'flex-start'}
      alignItems="center"
      xs={colums}
    >
      {label ? (
        <Text fontSize="18px" color="#818181">
          {label}
        </Text>
      ) : null}
      {children}
    </Grid>
  );
}

interface ITBodyCell {
  label?: string;
  colums: number;
  alignRight?: boolean;
  children?: React.ReactNode;
}
function TBodyCell(props: ITBodyCell) {
  const { label = '', colums, children, alignRight = false } = props;
  return (
    <Grid
      item
      display="flex"
      justifyContent={alignRight ? 'flex-end' : 'flex-start'}
      alignItems="center"
      xs={colums}
    >
      {label ? (
        <Text fontSize="20px" lineHeight="30px" color="#333333">
          {label}
        </Text>
      ) : null}
      {children}
    </Grid>
  );
}

function MobileCell(props: any) {
  const { track, toggleTrack, setTrackOwnership } = props;
  const { id, name, ownership, selected } = track;

  return (
    <Box
      borderBottom={1}
      borderColor="#DFE1E6"
      marginBottom="24px"
      paddingBottom="24px"
      sx={{
        ':nth-last-of-type(2)': {
          borderBottom: 'none',
          marginBottom: 0,
        },
      }}
    >
      <Box display="flex" alignItems="center">
        <Text fontSize="20px" color="#333333" flexGrow={1}>
          {name}
        </Text>
        <Box marginRight="-9px" marginTop="-8px">
          <Checkbox
            checked={selected}
            onChange={() => {
              toggleTrack(id);
            }}
          />
        </Box>
      </Box>
      <Box marginTop="24px">
        <Text fontSize="18px" color="#818181">
          % Owned by Artist
        </Text>
        <Box display="flex" marginTop="16px" marginRight="10px">
          <Text fontSize="20px" lineHeight="30px" color="#333333" marginRight="20px">
            {ownership}%
          </Text>
          <Slider
            step={1}
            min={0}
            max={100}
            value={ownership}
            defaultValue={ownership}
            onChange={(event, value) => {
              setTrackOwnership(id, value);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

function AddNewTrack(props: any) {
  const { tracks, artistSpotifyId, addTrack } = props;
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const onHandleAddTrack = useCallback((track: any) => {
    GaEvents[GaEventCategory.SONG_SELECTION_SCREEN].addTrack();
    addTrack(track);
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      borderTop={1}
      borderColor="#DFE1E6"
      textAlign="center"
      sx={{
        width: '100%',
      }}
      paddingTop="16px"
    >
      <Button
        variant="text"
        startIcon={<AddIcon />}
        sx={{ height: '50px', textTransform: 'none' }}
        onClick={handleOpenModal}
      >
        <Text
          fontSize="18px"
          sx={{
            textDecoration: 'underline',
          }}
        >
          Add new track
        </Text>
      </Button>
      <AddTrackModal
        tracks={tracks}
        open={openModal}
        handleClose={handleCloseModal}
        artistSpotifyId={artistSpotifyId}
        handleAddTrack={onHandleAddTrack}
      />
    </Box>
  );
}
