import React, { memo } from 'react';
// mui components
import { Slider as MuiSlider, sliderClasses, SliderProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledSlider = styled(MuiSlider)(({ theme }) => ({
  color: theme.colors.main.purple,
  height: '4px',
  [`& .${sliderClasses.track}`]: {
    border: 'none',
  },
  [`& .${sliderClasses.rail}`]: {
    backgroundColor: theme.colors.background.light.b3,
  },
  [`& .${sliderClasses.thumb}`]: {
    height: '16px',
    width: '16px',
    backgroundColor: theme.colors.main.purple,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  [`& .${sliderClasses.mark}`]: {
    width: '8px',
    height: '8px',
    backgroundColor: theme.colors.background.light.b3,
    borderRadius: '50%',
    [`&.${sliderClasses.markActive}`]: {
      backgroundColor: theme.colors.main.purple,
    },
  },
}));

const StyledSliderWithMarks = styled(StyledSlider)({
  [`& .${sliderClasses.track}`]: {
    transition:
      'left 1500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,width 1500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,bottom 1500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,height 1500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
  },
  [`& .${sliderClasses.thumb}`]: {
    transition:
      'box-shadow 1500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,left 1500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,bottom 1500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
});

interface OwnProps extends SliderProps {}

const Slider: React.FC<OwnProps> = (props) =>
  props.marks ? <StyledSliderWithMarks {...props} /> : <StyledSlider {...props} />;

export default memo(Slider);
