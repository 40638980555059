import React, { useCallback, useMemo, memo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
// mui components
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Grid,
  GridProps,
  debounce,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// controllers
import AutocompleteInputController from 'controllers/autocomplete-input-controller';
import SliderController from 'controllers/slider-controller';
// components
import ErrorModal from 'components/error-modal';
// custom hooks
import { IData, ITrackState } from 'hooks/useDealState';
import { useError } from 'hooks/useError';
// icons
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
// validation
import { yupResolver } from '@hookform/resolvers/yup';
import { Schema } from './validation';
// api
import api from 'utils/api';
// styles
import { colors } from 'styles/colors';

const StyledDialog = styled(Dialog)({
  '& .MuiDialogContent-root': {
    margin: '10px 0 24px',
    padding: 0,
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'center',
    height: '48px',
    padding: 0,
    '& button': {
      margin: '0 12px',
    },
  },
  '& .MuiDialog-paper': {
    width: '480px',
    padding: '24px',
    borderRadius: '8px',
    overflow: 'visible',
  },
});

const StyledText = styled(Typography)(({ theme }) => ({
  ...theme.fontStyles.bodyLight,
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const StyledDialogTitle: React.FC<DialogTitleProps> = (props) => {
  const { onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ display: 'flex', justifyContent: 'flex-end', height: '40px', padding: 0 }}
      {...other}
    >
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose} sx={{ width: '40px', height: '40px' }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const StyledButton = styled(Button)({
  height: '48px',
  width: '126px',
  textTransform: 'unset',
  boxShadow: 'none',
});

const InputContainer = styled((props: GridProps) => (
  <Grid container flexDirection="column" sx={{ marginBottom: '16px' }} {...props} />
))({});

interface OwnProps {
  open: boolean;
  handleClose: () => void;
  handleAddTrack: (trackInfo: ITrackState) => void;
  artistSpotifyId: string;
  tracks: any[];
}

const AddTrackModal: React.FC<OwnProps> = ({
  open,
  tracks: existingTracks,
  handleClose,
  handleAddTrack,
  artistSpotifyId,
}) => {
  const { message, setError } = useError();

  const [openErrorModal, setOpenErrorModal] = useState(false);

  const [allTracks, setAllTracks] = useState<IData[] | []>([]);

  const [isValidating, setValidating] = useState(false);

  const form = useForm({ resolver: yupResolver(Schema) });

  const options = useMemo(() => {
    if (allTracks.length) {
      const currentTracksIds = existingTracks.map(({ id }) => id);

      return allTracks
        .filter(({ id, name }) => {
          return !currentTracksIds.includes(id);
        })
        .map(({ id, name, date_released }) => ({
          label: name,
          value: id,
          date_released,
        }));
    }

    return [];
  }, [allTracks, existingTracks]);

  const sliderValue = useWatch({
    control: form.control,
    name: 'ownership',
    defaultValue: 0,
  });

  const formHasErrors = useMemo(
    () => Object.keys(form.formState.errors).length > 0,
    [form.formState.errors]
  );

  const handleCloseModal = useCallback(() => {
    handleClose();
    form.reset({ ownership: 0, date_released: '', id: '' });
  }, [form, handleClose]);

  const handleSelectTrack = useCallback(
    (trackId: string | number) => {
      const trackInfo = allTracks.filter(({ id }) => id === trackId)[0];
      //@ts-ignore
      form.setValue('ownership', trackInfo.ownership);
      //@ts-ignore
      form.setValue('album_name', trackInfo.album_name);
      //@ts-ignore
      form.setValue('artists', trackInfo.artists);
      //@ts-ignore
      form.setValue('artists_count', trackInfo.artists_count);
      form.setValue('date_released', trackInfo.date_released);
      form.setValue('id', trackInfo.id);
      form.setValue('name', trackInfo.name);
      //@ts-ignore
      form.setValue('popularity', trackInfo.popularity);
      form.setValue('selected', true);
    },
    // eslint-disable-next-line
    [options, form]
  );

  const searchTrack = useCallback(
    async (value: string) => {
      try {
        if (value) {
          setValidating(true);
          const data: IData[] = await api.spotify.searchTracks({
            params: { track: value, artistSpotifyId },
          });
          setAllTracks(data);
        } else {
          setAllTracks([]);
        }
      } catch (err) {
        setError(err);
        setOpenErrorModal(true);
      } finally {
        setValidating(false);
      }
    },
    // eslint-disable-next-line
    [setError]
  );

  const handleSearchTrack = debounce(searchTrack, 300);

  const onSubmit = useCallback(
    (data: any) => {
      handleAddTrack(data);
      handleCloseModal();
    },
    [handleAddTrack, handleCloseModal]
  );

  return (
    <>
      <StyledDialog
        onClose={handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <FormProvider {...form}>
          <StyledDialogTitle id="customized-dialog-title" onClose={handleCloseModal} />
          <DialogContent>
            <Grid container justifyContent="center">
              <Typography
                sx={{
                  marginBottom: '8px',
                  fontSize: '32px',
                  lineHeight: '28px',
                  fontWeight: 'bold',
                  color: colors.text.light.title,
                }}
              >
                Add new track
              </Typography>
            </Grid>
            <Grid container justifyContent="center">
              <Typography
                sx={{
                  fontSize: '18px',
                  lineHeight: '26px',
                  color: formHasErrors ? colors.system.error : colors.text.light.label,
                }}
              >
                Please provide track details
              </Typography>
            </Grid>
            <Grid container flexDirection="column" sx={{ marginTop: '24px' }}>
              <InputContainer>
                <AutocompleteInputController
                  name="id"
                  options={options}
                  label="Track name"
                  loading={isValidating}
                  additionalOnChange={handleSelectTrack}
                  handleChange={handleSearchTrack}
                />
              </InputContainer>
              {/* <InputContainer>
                <DatepickerController name="date_released" label="Date release" readOnly />
              </InputContainer> */}
              <InputContainer sx={{ padding: '0 16px' }}>
                <StyledText>Artist ownership (%)</StyledText>
                <Grid container alignItems="center" sx={{ marginTop: '16px' }}>
                  <Grid container item xs={2}>
                    <StyledText>{sliderValue}%</StyledText>
                  </Grid>
                  <Grid container item xs={10}>
                    <SliderController name="ownership" min={0} max={100} defaultValue={0} />
                  </Grid>
                </Grid>
              </InputContainer>
            </Grid>
          </DialogContent>
          <DialogActions>
            <StyledButton
              variant="contained"
              sx={{
                backgroundColor: colors.main.white,
                color: colors.text.light.body,
                border: '1px solid #2B2B2B',
                '&:hover': {
                  backgroundColor: colors.main.white,
                },
              }}
              onClick={handleCloseModal}
            >
              Cancel
            </StyledButton>
            <StyledButton
              onClick={form.handleSubmit(onSubmit)}
              variant="contained"
              sx={{
                backgroundColor: colors.main.purple,
                '&:hover': {
                  backgroundColor: colors.main.purpleDark,
                },
              }}
            >
              Add track
            </StyledButton>
          </DialogActions>
        </FormProvider>
      </StyledDialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={() => setOpenErrorModal(false)}
        message={message}
      />
    </>
  );
};

export default memo(AddTrackModal);
