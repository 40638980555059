import Text from './text';
import { Box, Container, IconButton } from '@mui/material';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';

interface IFlatDesignErrorProps {
  message: string;
  onClose: () => void;
}

export default function FlatDesignError(props: IFlatDesignErrorProps) {
  const { message, onClose } = props;

  return (
    <Box position="fixed" bottom={0} left={0} right={0} zIndex={2} bgcolor="#FDEAE8" padding="20px">
      <Container maxWidth="md">
        <Box>
          <Box display="flex" alignItems="center" justifyContent="center" flexGrow={1}>
            <WarningIcon />
            <Text fontSize="20px" paddingLeft="10px">
              {message}
            </Text>
          </Box>
          <Box position="absolute" right={0} top={0} marginTop="10px" marginRight="10px">
            <IconButton aria-label="close-error-dialouge" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
