export const authPages = [
  '/sign-in',
  '/sign-up',
  '/forgot-password',
  '/reset-password',
  '/profile',
  '/intro',
];

export const exampleResponseData = [
  {
    id: 1,
    label: 'Hinder',
    amount: 459000,
    status: 'progress',
    submitted_date: null,
    lengthOfCommitment: 2,
    artistHomeRate: 50,
  },
  {
    id: 2,
    label: 'Hinder',
    amount: 459000,
    status: 'completed',
    submitted_date: '25/11/2021',
    lengthOfCommitment: 2,
    artistHomeRate: 50,
  },
  {
    id: 3,
    label: 'Meego',
    amount: 759000,
    status: 'progress',
    submitted_date: null,
    lengthOfCommitment: 3,
    artistHomeRate: 25,
  },
  {
    id: 4,
    label: 'Meego',
    amount: 759000,
    status: 'completed',
    submitted_date: '23/09/2021',
    lengthOfCommitment: 3,
    artistHomeRate: 25,
  },
];

export const exampleTracksData = [
  { id: 1, label: 'Goodbye' },
  { id: 2, label: 'Tell a lie' },
  { id: 3, label: 'Moonmap' },
  { id: 4, label: 'Painting' },
  { id: 5, label: 'Chase' },
  { id: 6, label: 'Goodbye' },
  { id: 7, label: 'Tell a lie' },
  { id: 8, label: 'Moonmap' },
  { id: 9, label: 'Painting' },
  { id: 10, label: 'Chase' },
];
