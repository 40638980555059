import makeAxiosRequest, { GetAxiosResponse } from './axios.instance';

export interface RequestList {
  auth: {
    signIn: GetAxiosResponse;
    signUp: GetAxiosResponse;
    getProfile: GetAxiosResponse;
    updateProfile: GetAxiosResponse;
    confirmEmail: GetAxiosResponse;
    resetPassword: GetAxiosResponse;
    changePassword: GetAxiosResponse;
    confirmResetPassword: GetAxiosResponse;
  };
  spotify: {
    getSpotifyArtist: GetAxiosResponse;
    getTopTracks: GetAxiosResponse;
    getAllTracks: GetAxiosResponse;
    searchTracks: GetAxiosResponse;
  };
  deals: {
    getDealList: GetAxiosResponse;
    createDeal: GetAxiosResponse;
    dealSubmit: GetAxiosResponse;
    getDeal: GetAxiosResponse;
    updateDeal: GetAxiosResponse;
    calculateAdvance: GetAxiosResponse;
    uploadReport: GetAxiosResponse;
  };
  reports: {
    createInitialReport: GetAxiosResponse;
    finaliseReport: GetAxiosResponse;
    remove: GetAxiosResponse;
    statusUploaded: GetAxiosResponse;
    getDownloadUrl: GetAxiosResponse;
  };
}

const api = {
  auth: {
    signIn: makeAxiosRequest('/auth/login', 'post'),
    signUp: makeAxiosRequest('/users', 'post'),
    getProfile: makeAxiosRequest('auth/profile', 'get'),
    updateProfile: makeAxiosRequest('/users', 'patch'),
    confirmEmail: makeAxiosRequest('/users/confirm-email', 'post'),
    resetPassword: makeAxiosRequest('/users/reset-password', 'post'),
    changePassword: makeAxiosRequest('/users/change-password', 'post'),
    confirmResetPassword: makeAxiosRequest('/users/confirm-reset-password', 'post'),
  },
  spotify: {
    getSpotifyArtist: makeAxiosRequest((query) => `/spotify/get-artist/${query}`, 'get'),
    getTopTracks: makeAxiosRequest((id) => `/spotify/get-top-tracks/${id}`, 'get'),
    getAllTracks: makeAxiosRequest((id) => `/spotify/get-all-tracks/${id}`, 'get'),
    searchTracks: makeAxiosRequest('/spotify/search-tracks', 'get'),
  },
  deals: {
    getDealList: makeAxiosRequest('/deals', 'get'),
    createDeal: makeAxiosRequest('/deals', 'post'),
    dealSubmit: makeAxiosRequest((id) => `/deals/${id}/submit`, 'post'),
    getDeal: makeAxiosRequest((id) => `/deals/${id}`, 'get'),
    updateDeal: makeAxiosRequest((id) => `/deals/${id}`, 'put'),
    calculateAdvance: makeAxiosRequest((id) => `/deals/${id}/advance`, 'get'),
    uploadReport: makeAxiosRequest((id) => `/deals/${id}/file`, 'post'),
  },
  reports: {
    createInitialReport: makeAxiosRequest((id) => `/reports/deal/${id}`, 'post'),
    finaliseReport: makeAxiosRequest('/reports', 'post'),
    remove: makeAxiosRequest((id) => `/reports/${id}`, 'delete'),
    statusUploaded: makeAxiosRequest((id) => `/reports/${id}/status-uploaded`, 'post'),
    getDownloadUrl: makeAxiosRequest((id) => `/reports/${id}/get-download-url`, 'get'),
  },
} as RequestList;

export default api;
