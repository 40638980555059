// pages
import NewDeal from 'pages/newdeal';
import Homepage from '../homepage';
// auth
import SignIn from '../auth/sign-in';
import SignUp from '../auth/sign-up';
import ForgotPassword from '../auth/forgot-password';
import ResetPassword from '../auth/reset-password';
import Intro from '../auth/intro';
// profile
import Profile from '../profile';

export const unauthorizedRoutes = [
  { path: '/', component: Homepage, exact: true },
  // auth
  { path: '/sign-in', component: SignIn, exact: true },
  { path: '/sign-up', component: SignUp, exact: true },
  { path: '/forgot-password', component: ForgotPassword, exact: true },
  { path: '/new-password', component: ResetPassword, exact: true },
];

export const authorizedRoutes = [
  { path: '/', component: Homepage, exact: true },
  // auth
  { path: '/intro', component: Intro, exact: true },
  // deal
  { path: '/deal/:id', component: NewDeal, exact: true },
  // profile
  { path: '/profile', component: Profile },
];
