import React, { useCallback, memo } from 'react';
import { useHistory } from 'react-router-dom';
// mui components
import { Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
// styles
import { fontStyles } from 'styles/font-styles';
import { colors } from 'styles/colors';
import Text from 'components/text';

const StyledSkipButton = styled(Button)(({ theme }) => ({
  ...theme.fontStyles.placeholder,
  textTransform: 'unset',
  textDecoration: 'underline',
}));

const DealSubmitted: React.FC = () => {
  const history = useHistory();

  const onSkip = useCallback(() => {
    history.push('/profile/deals');
  }, [history]);

  return (
    <>
      <Grid
        container
        flexDirection="column"
        alignItems="center"
        maxWidth="600px"
        marginX="auto"
        marginTop="60px"
      >
        <SuccessIcon />
        <Text
          marginTop="20px"
          fontSize="20px"
          fontWeight="bold"
          lineHeight="30px"
          marginBottom="8px"
        >
          Thanks for submitting!
        </Text>
        <Typography
          textAlign="center"
          sx={{ ...fontStyles.subtext, color: colors.text.light.label }}
        >
          Our team will follow-up within 2 business days with an exact offer and contract. Once a
          deal is signed, payment happens within the week
        </Typography>

        <Grid
          container
          flexDirection="column"
          alignItems="center"
          sx={{ maxWidth: '500px' }}
          marginTop="44px"
        >
          <StyledSkipButton variant="text" onClick={onSkip}>
            Go to my deals
          </StyledSkipButton>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(DealSubmitted);
