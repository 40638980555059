import { Box, Container } from '@mui/material';
import { ISteps } from '../store';
import Text from 'components/text';
import { useWidth } from 'hooks/useWidth';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import { colors } from 'styles/colors';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as ChartIcon } from 'assets/icons/chart.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as DealIcon } from 'assets/icons/deal.svg';
import { ReactComponent as MusicIcon } from 'assets/icons/music.svg';

interface IProps {
  steps: ISteps[];
  currentStepNumber: number;
}

function DesktopSteps(props: IProps) {
  const { steps } = props;
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      gap="10px"
      width="100%"
      marginY="18px"
      sx={{
        userSelect: 'none',
      }}
    >
      {steps.map((step, index) => {
        const { icon, active } = step;
        return (
          <Box display="flex" alignItems="center" key={index}>
            <RenderStepIcon icon={icon} active={active} />
            <Text fontSize="18px" color={active ? '#7801EF' : '#8b8b8b'}>
              {step.name}
            </Text>
          </Box>
        );
      })}
    </Box>
  );
}

interface IMobileStepsProps {
  steps: ISteps[];
  currentStepNumber: number;
}

function MobileSteps(props: IMobileStepsProps) {
  const { steps, currentStepNumber } = props;
  const currentStep: ISteps | undefined = steps.find((step) => step.id === currentStepNumber);
  const nextStep: ISteps | undefined = steps.find((step) => step.id === currentStepNumber + 1);

  return (
    <Box
      display="flex"
      width="100%"
      marginTop="15px"
      marginBottom="5px"
      sx={{
        userSelect: 'none',
      }}
    >
      <Box marginRight="15px" position="relative">
        <CircularProgress
          variant="determinate"
          sx={{
            color: colors.main.purple,
            zIndex: 2,
            position: 'relative',
          }}
          size={40}
          thickness={4}
          value={currentStepNumber * 25}
        />
        <CircularProgress
          variant="determinate"
          // disableShrink
          sx={{
            color: '#ddd',
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={40}
          thickness={4}
          value={100}
        />
        <Box
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          position="absolute"
          alignItems="center"
          paddingBottom="4px"
          justifyContent="center"
        >
          <Text fontSize="14px" fontWeight="bold" color="#666" letterSpacing="1.5px">
            {currentStep?.id}/{steps.length}
          </Text>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center" paddingBottom="4px">
        <Text fontSize="18px" color="#7801EF">
          {currentStep?.name}
        </Text>
        {nextStep && (
          <Text marginTop="2px" fontSize="14px" color="#666">
            Next: {nextStep?.name}
          </Text>
        )}
      </Box>
    </Box>
  );
}

export default function Steps(props: IProps) {
  const { steps, currentStepNumber } = props;
  const { isMobileOrTablet } = useWidth();

  return (
    <Container maxWidth="md">
      {isMobileOrTablet ? (
        <MobileSteps steps={steps} currentStepNumber={currentStepNumber} />
      ) : (
        <DesktopSteps steps={steps} currentStepNumber={currentStepNumber} />
      )}
    </Container>
  );
}

interface IRenderStepIconProps {
  icon: string;
  active?: boolean;
}

function RenderStepIcon(props: IRenderStepIconProps) {
  const { icon, active = false } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '40px',
        height: '40px',
        marginRight: '16px',
        borderRadius: '20px',
        backgroundColor: '#f7f7f7',
        '& path': {
          fill: active ? '#7801EF' : '#B8B8BA',
        },
      }}
    >
      {icon === 'ChartIcon' && <ChartIcon />}
      {icon === 'SongIcon' && <MusicIcon />}
      {icon === 'DealIcon' && <DealIcon />}
      {icon === 'UploadIcon' && <UploadIcon />}
      {icon === 'CheckIcon' && <CheckIcon />}
    </Box>
  );
}
