import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
// mui component
import { Grid, GridProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import {
  StyledText,
  StyledLabel,
  StyledValue,
  StyledAmount,
  StyledHeading,
} from '../styled-components';
import ErrorModal from 'components/error-modal';
import Text from 'components/text';
// utils
import { formatValueToDollars } from 'utils/helpers';
// icons
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow_left.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
// import { ReactComponent as PDFIcon } from 'assets/icons/pdf.svg';
// hooks
import { useGetDeal } from 'hooks/swrRequests';
import { useError } from 'hooks/useError';
// styles
import { fontStyles } from 'styles/font-styles';
import { colors } from 'styles/colors';

const StyledValueContainer = styled((props: GridProps) => (
  <Grid container flexDirection="column" item xs={12} sm={6} md={4} {...props} />
))({
  marginBottom: '40px',
});

const StyledSubHeading = styled(Typography)(({ theme }) => ({
  marginBottom: '8px',
  ...theme.fontStyles.tiny,
  color: theme.colors.text.light.title,
}));

const StyledFileText = styled(Typography)(({ theme }) => ({
  ...theme.fontStyles.subtext,
  color: theme.colors.text.light.title,
}));

const StyledBreadcrumbsLink = styled(Link)(({ theme }) => ({
  ...theme.fontStyles.subtext,
  color: theme.colors.text.light.label,
  textDecoration: 'unset',
}));

const DealDetailsSection: React.FC = () => {
  const history = useHistory();

  const [openErrorModal, setOpenErrorModal] = useState(false);

  const { message, setError } = useError();

  const { id } = useParams<{ id: string }>();

  const { data, error } = useGetDeal(id);

  const itemIsCompleted = useMemo(() => data?.status === 'submitted', [data]);

  const statusLabel = useMemo(
    () => (itemIsCompleted ? 'Submitted' : 'In progress'),
    [itemIsCompleted]
  );

  const handleCloseErrorModal = useCallback(() => {
    setOpenErrorModal(false);
  }, []);

  useEffect(() => {
    if (error) {
      setError(error);
      setOpenErrorModal(true);
      history.push('/profile/deals');
    }
  }, [error, history, setError]);

  return (
    <Grid container flexDirection="column" sx={{ margin: '40px' }}>
      <Grid
        container
        alignItems="center"
        sx={{
          '& svg': {
            width: '11px',
            height: '11px',
            transform: 'rotate(180deg)',
            margin: '0 18px',
          },
        }}
      >
        <StyledBreadcrumbsLink to="/profile/deals">Deals</StyledBreadcrumbsLink> <ArrowLeftIcon />{' '}
        <StyledText>{data?.artistName}</StyledText>
      </Grid>
      <Grid container>
        <Typography sx={{ ...fontStyles.h3, color: colors.text.light.title }}>Details</Typography>
      </Grid>
      <Grid
        container
        flexWrap="nowrap"
        sx={{
          margin: { md: '40px 0 0', xs: '40px 0 20px' },
          flexDirection: { md: 'row', xs: 'column' },
        }}
      >
        <Grid container item md={2} xs={12}>
          <img
            src={data?.images[0].url}
            alt="test_avatar"
            width={88}
            height={88}
            style={{ borderRadius: '50%' }}
          />
        </Grid>
        <Grid container flexDirection="column" item md={10} xs={12}>
          <StyledHeading
            sx={{
              paddingBottom: '16px',
              borderBottom: `1px solid ${colors.background.light.b4}`,
            }}
          >
            {data?.artistName}
          </StyledHeading>
          <Grid container sx={{ marginTop: '40px' }}>
            <StyledValueContainer>
              <StyledLabel>Amount:</StyledLabel>
              <StyledValue>
                <StyledAmount>{formatValueToDollars(data?.advanceMin ?? 0)}</StyledAmount> -{' '}
                <StyledAmount>{formatValueToDollars(data?.advanceMax ?? 0)}</StyledAmount>
              </StyledValue>
            </StyledValueContainer>
            <StyledValueContainer>
              <StyledLabel>Status:</StyledLabel>
              <StyledText>
                {statusLabel} {itemIsCompleted && <CheckIcon />}
              </StyledText>
            </StyledValueContainer>
            <StyledValueContainer>
              <StyledLabel>Submitted date:</StyledLabel>
              <StyledValue>{data?.submittedDate || '-'}</StyledValue>
            </StyledValueContainer>
            <StyledValueContainer>
              <StyledLabel>Length of commitment:</StyledLabel>
              <StyledValue>
                {data?.lengthOfCommitment} year
                {data?.lengthOfCommitment && data?.lengthOfCommitment > 1 && 's'}
              </StyledValue>
            </StyledValueContainer>
            {/* <StyledValueContainer>
              <StyledLabel>Artist take home rate:</StyledLabel>
              <StyledValue>{data?.artistHomeRate}%</StyledValue>
            </StyledValueContainer>
            <StyledValueContainer>
              <StyledLabel>Contract:</StyledLabel>
              <StyledFileLink
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  '& svg': {
                    marginLeft: '10px',
                  },
                }}
              >
                View contract <PDFIcon />
              </StyledFileLink>
            </StyledValueContainer> */}
          </Grid>
          <Grid container flexDirection="column">
            <StyledSubHeading>Uploaded reports</StyledSubHeading>
            <Grid
              container
              flexDirection="column"
              sx={{ padding: '24px', backgroundColor: colors.background.light.b1 }}
            >
              {data?.reports.map((item) => (
                <Grid
                  key={item.id}
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ padding: '8px 0', borderBottom: `1px solid ${colors.background.light.b4}` }}
                >
                  <StyledFileText>{item.name}</StyledFileText>
                  <Text
                    component="a"
                    fontSize="18px"
                    color={colors.text.light.body}
                    lineHeight="26px"
                    href={item.hubspotFileUrl}
                    target="_blank"
                  >
                    View
                  </Text>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid container flexDirection="column" sx={{ marginTop: '40px' }}>
            <StyledSubHeading>Track list</StyledSubHeading>
            <Grid
              container
              flexDirection="column"
              sx={{ padding: '16px 24px', backgroundColor: colors.background.light.b1 }}
            >
              {data?.tracks.map(({ trackSpotifyId, name }, i) => (
                <StyledFileText key={trackSpotifyId} sx={{ padding: '8px 0' }}>
                  {i + 1}. {name}
                </StyledFileText>
              ))}
            </Grid>
          </Grid>
          <ErrorModal open={openErrorModal} handleClose={handleCloseErrorModal} message={message} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DealDetailsSection;
