import React from 'react';
import { Box, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Text from 'components/text';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow_left.svg';

interface IProps {
  currentStepNumber: number;
  children: React.ReactNode;
  setCurrentStepNumber?(step: number): void;
}

export default function Footer(props: IProps) {
  const { currentStepNumber = 1, children, setCurrentStepNumber = () => {} } = props;

  return (
    <Box position="fixed" left={0} right={0} bottom={0} zIndex={2}>
      <Container maxWidth="md">
        <Box
          height="80px"
          borderTop={1}
          borderColor="#DFE1E6"
          bgcolor="#fff"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {currentStepNumber > 1 ? (
            <Box>
              <LoadingButton
                variant="text"
                startIcon={<ArrowLeftIcon />}
                onClick={() => {
                  setCurrentStepNumber(currentStepNumber - 1);
                }}
                sx={{
                  height: '48px',
                  textTransform: 'unset',
                  padding: '9px 24px',
                }}
              >
                <Text color="#000" fontWeight={500} fontSize="20px">
                  Back
                </Text>
              </LoadingButton>
            </Box>
          ) : null}
          <Box />
          {children}
        </Box>
      </Container>
    </Box>
  );
}
