import React from 'react';

import { Box } from '@mui/material';
import { BoxProps, keyframes } from '@mui/system';

interface IBar extends BoxProps {
  to: string;
  from: string;
  delay?: string;
  visible?: boolean;
  duration?: string;
  animateOpacity?: boolean;
  mode?: 'forwards' | 'backwards' | 'both';
  animation?: 'ease-out' | 'ease-in' | 'ease-in-out';
}

interface IBarAnimator extends React.FC<BoxProps> {
  Bar: React.FC<IBar>;
}

interface IRenderAnimationFunc {
  animation: string;
  suffix: string;
  delay: string;
  visible: boolean;
}

const renderAnimation = ({ animation, suffix, delay, visible }: IRenderAnimationFunc): object => {
  if (visible) {
    return {
      animation: `${keyframes(`${animation}`)} ${suffix}`,
      animationDelay: delay,
    };
  }

  return {};
};

const BarAnimator: IBarAnimator = ({ children, ...rest }) => {
  return (
    <Box position="relative" {...rest}>
      {children}
    </Box>
  );
};

BarAnimator.Bar = ({
  sx = {},
  children,
  delay = '0ms',
  duration = '500ms',
  visible = true,
  mode = 'forwards',
  animation = 'ease-out',
  animateOpacity = false,
  from,
  to,
  ...rest
}) => {
  return (
    <Box
      position="absolute"
      zIndex={-1}
      sx={{
        opacity: animateOpacity ? 0 : 1,
        ...renderAnimation({
          animation: `
            from { 
              ${from}
              ${animateOpacity ? 'opacity: 0;' : ''}
            } to { 
              ${to}
              ${animateOpacity ? 'opacity: 1;' : ''}
            }
          `,
          suffix: `${duration} ${mode} ${animation}`,
          delay,
          visible,
        }),
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default BarAnimator;
