import Text from 'components/text';

export default function FaqSupportLink(props: { onClick: () => void }) {
  const { onClick } = props;
  return (
    <Text
      component="a"
      href="https://meetings.hubspot.com/theo-mendez/fineart-web-meeting-request"
      target="_blank"
      color="#000000"
      fontSize="18px"
      position="fixed"
      right={0}
      bottom={{
        md: 0,
        xs: 'auto',
      }}
      top={{
        md: 'auto',
        xs: 90,
      }}
      marginRight="20px"
      marginBottom="100px"
      onClick={onClick}
    >
      Questions? Talk to us
    </Text>
  );
}
