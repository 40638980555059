import * as yup from 'yup';
import { emailRule, passwordRule, confirmPasswordRule } from 'utils/validation-rules';

export const SignUpSchema = yup.object({
  artistSpotifyId: yup.string().nullable().required('Field is required'),
  fullName: yup.string().required('Field is required'),
  email: emailRule,
  password: passwordRule,
  passwordConfirm: confirmPasswordRule('password'),
});
