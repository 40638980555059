import React, { memo, useMemo } from 'react';
// mui components
import { styled, createStyles } from '@mui/material/styles';

const defaultStyles = createStyles({
  position: 'fixed',
  bottom: 0,
  right: 0,
  transform: 'translateX(-30px) translateY(-26px)',
  fontSize: '18px',
  lineHeight: '26px',
  textDecoration: 'underline',
});

const StyledStepsQALink = styled('a')(({ theme }) =>
  Object.assign(defaultStyles, {
    color: theme.colors.main.black,
    '@media (max-width: 1300px)': {
      right: 'unset',
      margin: '0 auto',
      transform: 'translateY(-26px)',
      zIndex: 10,
    },
    '@media (max-width: 600px)': {
      position: 'unset',
      margin: '6px 32px 0 0',
      alignSelf: 'flex-end',
    },
  })
);

const StyledProfileQALink = styled('a')(({ theme }) =>
  Object.assign(defaultStyles, {
    position: 'absolute',
    color: theme.colors.main.black,
    '@media (max-width: 600px)': {
      bottom: 'unset',
      top: 0,
      transform: 'translateX(-30px) translateY(10px)',
    },
  })
);

const StyledAuthQALink = styled('a')(({ theme }) =>
  Object.assign(defaultStyles, {
    color: theme.colors.main.white,
    '@media (max-width: 600px)': {
      position: 'unset',
      transform: 'unset',
      marginBottom: '20px',
    },
  })
);

interface OwnProps {
  isAuth?: boolean;
  isProfile?: boolean;
}

const TalkToUsLink: React.FC<OwnProps> = ({ isAuth, isProfile }) => {
  const CurrentLinkComponent = useMemo(
    () => (isAuth ? StyledAuthQALink : isProfile ? StyledProfileQALink : StyledStepsQALink),
    [isAuth, isProfile]
  );

  return (
    <CurrentLinkComponent
      href="https://meetings.hubspot.com/theo-mendez/fineart-web-meeting-request"
      target="_blank"
      rel="noopener noreferrer"
    >
      Questions? Talk to us
    </CurrentLinkComponent>
  );
};

export default memo(TalkToUsLink);
