import React, { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';
// mui components
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import PurpleButton from 'components/purple-button';
// layouts
import AuthLayout from 'layouts/auth-layout';
// icons
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
import { GaEvents, GaEventCategory } from 'utils/gaEvents';

const StyledHeading = styled(Typography)(({ theme }) => ({
  margin: '40px 0 35px',
  ...theme.fontStyles.h3,
  color: theme.colors.main.white,
}));

const StyledText = styled(Typography)(({ theme }) => ({
  marginBottom: '10px',
  ...theme.fontStyles.subtext,
  color: theme.colors.main.white,
}));

const CompletedSignUp: React.FC = () => {
  useEffect(() => {
    GaEvents[GaEventCategory.ACCOUNT_PAGE].accountCreated();
  }, []);

  return (
    <AuthLayout>
      <Grid container flexDirection="column" alignItems="center" sx={{ maxWidth: '500px' }}>
        <SuccessIcon />
        <StyledHeading>Thank you!</StyledHeading>
        <StyledText>An email has been sent to you.</StyledText>
        <StyledText>
          Please check your email and follow the instruction to view the offer
        </StyledText>
        <PurpleButton component={Link} to="/sign-in" sx={{ width: '124px', margin: '35px 0' }}>
          Close
        </PurpleButton>
      </Grid>
    </AuthLayout>
  );
};

export default memo(CompletedSignUp);
