import { useEffect, useState } from 'react';
import { Box, Grid, Typography, BoxProps } from '@mui/material';
import Text from 'components/text';
import Tooltip from 'components/tooltip';
import { styled } from '@mui/material/styles';
import AnimatedNumbers from 'react-animated-numbers';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import Footer from '../Footer';
import SubmitButton from '../SubmitButton';
import Slider from 'components/slider';
import CircularProgress from '@mui/material/CircularProgress';
import api from 'utils/api';
import FaqSupportLink from '../FaqSupportLink';
import { GaEvents, GaEventCategory } from 'utils/gaEvents';
import ErrorModal from 'components/error-modal';
import { useError } from 'hooks/useError';
import { retry } from 'utils/helpers';

const StyledDealValue = styled(Typography)({
  fontSize: '40px',
  lineHeight: '47px',
  fontFamily: '"Founders Grotesk", sans serif',
});

const StyledDollar = styled(StyledDealValue)({
  marginRight: '1px',
  transition: 'transform 1.5s ease-in-out',
});

const StyledSliderBox = styled((props: BoxProps) => (
  <Box {...props} display="flex" alignItems="center" />
))({
  transition: 'opacity 1.2s ease-in-out',
  '& .animated-container > div': {
    textAlign: 'center',
  },
});

interface IEstimates {
  deal_advance: {
    [key: string]: {
      min: number | string | any;
      max: number | string | any;
    };
  };
  total_streams_of_all_tracks: number | string | any;
  monthly_revenue: {
    total: number | string | any;
    range: [number | string | any, number | string | any];
  };
}

export default function CalculateDeal(props: any) {
  const { state, setCurrentStepNumber, setFormData } = props.store;
  const { commitmentLength } = state.form;
  const { currentStepNumber, tracks, deal, isFullCatalogue } = state;
  const [estimates, setEstimates] = useState<IEstimates | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const totalSelectedSongs = tracks.filter((track: any) => track.selected).length;
  const { message, setError } = useError();
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const { deal_advance }: any = estimates || {};
  const getMinAdvance = () => {
    if (!deal_advance) return 0;
    return deal_advance.hasOwnProperty(commitmentLength) ? deal_advance[commitmentLength].min : 0;
  };
  const getMaxAdvance = () => {
    if (!deal_advance) return 0;
    return deal_advance[commitmentLength].max ? deal_advance[commitmentLength].max : 0;
  };

  useEffect(() => {
    GaEvents[GaEventCategory.CALCULATION_SCREEN].viewed();
  }, []);

  useEffect(() => {
    retry(
      () =>
        api.deals.calculateAdvance({
          id: deal.id,
        }),
      5
    )
      .then((res) => {
        setEstimates(res.estimates);
      })
      .catch(() => {
        setError(
          'Something went wrong while calculating the deal. Please refresh this page and try again.'
        );
        setOpenErrorModal(true);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  async function handleSubmit(event: any) {
    event.preventDefault();
    setLoadingSubmit(true);
    const [_minRevenueLastMonthSelectedTracks = 0, _maxRevenueLastMonthSelectedTracks = 0] =
      estimates?.monthly_revenue?.range || [0, 0];
    const maxRevenueLastMonthSelectedTracks = parseInt(_maxRevenueLastMonthSelectedTracks);
    const minRevenueLastMonthSelectedTracks = parseInt(_minRevenueLastMonthSelectedTracks);
    const revenueLastMonthSelectedTracks = parseInt(estimates?.monthly_revenue?.total || 0);

    try {
      await api.deals.updateDeal({
        id: deal.id,
        data: {
          lengthOfCommitment: commitmentLength,
          lambdaPayload: { estimates },
          advanceMax: getMaxAdvance(),
          advanceMin: getMinAdvance(),
          maxRevenueLastMonthSelectedTracks,
          minRevenueLastMonthSelectedTracks,
          revenueLastMonthSelectedTracks,
        },
      });
      GaEvents[GaEventCategory.CALCULATION_SCREEN].confirmed();
      setCurrentStepNumber(currentStepNumber + 1);
    } catch (err) {
      setError(
        'Something went wrong while updating deal advance. Please refresh this page and try again.'
      );
      setOpenErrorModal(true);
    } finally {
      setLoadingSubmit(false);
    }
  }

  return (
    <Box paddingBottom="80px">
      <Box
        paddingTop={{
          xs: '12px',
          sm: '22px',
        }}
        paddingBottom="32px"
      >
        <Text textAlign="center" fontSize="32px" lineHeight="40px" fontWeight="bold">
          Calculate deal
        </Text>
        <Text textAlign="center" fontSize="18px" lineHeight="26px" marginTop="8px">
          Adjust the sliders to see the estimated advance amount (USD)
        </Text>
      </Box>
      {loading ? (
        <Box paddingY="100px" textAlign="center">
          <CircularProgress sx={{ color: '#7801ef' }} />
        </Box>
      ) : (
        <>
          <Box
            display={{
              xs: 'block',
              sm: 'flex',
            }}
          >
            <Box
              sx={{
                width: {
                  xs: '100%',
                  sm: '50%',
                },
              }}
              borderRight={{
                xs: 'none',
                sm: '1px solid #DFE1E6',
              }}
              textAlign={{
                xs: 'left',
                sm: 'right',
              }}
              paddingRight={{
                xs: '0px',
                sm: '40px',
                md: '76px',
              }}
              paddingBottom={{
                xs: '15px',
                sm: '0px',
              }}
            >
              <Box
                display="flex"
                justifyContent={{
                  xs: 'space-between',
                  sm: 'flex-end',
                }}
                alignItems="center"
              >
                <Text fontSize="18px" lineHeight="26px" color="#484848">
                  {isFullCatalogue ? 'Entire catalogue' : 'Selected tracks:'}
                  {!isFullCatalogue ? (
                    <Text component="span" color="#7801EF" paddingX="8px" fontSize="20px">
                      {totalSelectedSongs}
                    </Text>
                  ) : (
                    <Box display="inline-block" paddingX="8px" />
                  )}
                </Text>
                <Text
                  component="span"
                  color="#000000"
                  onClick={() => {
                    GaEvents[GaEventCategory.CALCULATION_SCREEN].editTracks();
                    setCurrentStepNumber(currentStepNumber - 1);
                  }}
                  fontSize="18px"
                  lineHeight="26px"
                  sx={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  Edit
                </Text>
              </Box>
            </Box>
            <Box
              sx={{
                width: {
                  xs: '100%',
                  sm: '50%',
                },
              }}
              paddingLeft={{
                xs: '0px',
                sm: '40px',
                md: '76px',
              }}
              display="flex"
              alignItems="center"
            >
              <Tooltip title="This percentage of the song's revenue goes towards recoupment">
                <Box display="flex" marginRight="5px">
                  <InfoIcon />
                </Box>
              </Tooltip>
              <Text fontSize="18px" lineHeight="26px" color="#484848">
                Recoupment rate:
                <Text component="span" color="#7801EF" paddingX="8px" fontSize="20px">
                  70%
                </Text>
              </Text>
            </Box>
          </Box>

          <Grid container justifyContent="center" sx={{ margin: '40px 0' }}>
            <StyledSliderBox>
              <StyledDollar>$</StyledDollar>
              <AnimatedNumbers
                includeComma
                animateToNumber={getMinAdvance()}
                fontStyle={{ fontSize: '40px', lineHeight: '47px' }}
                configs={[{ mass: 1, tension: 300, friction: 40 }]}
              />
            </StyledSliderBox>
            <StyledDealValue sx={{ margin: '0 24px' }}>-</StyledDealValue>
            <StyledSliderBox>
              <StyledDollar>$</StyledDollar>
              <AnimatedNumbers
                includeComma
                animateToNumber={getMaxAdvance()}
                fontStyle={{ fontSize: '40px', lineHeight: '47px' }}
                configs={[{ mass: 1, tension: 300, friction: 40 }]}
              />
            </StyledSliderBox>
          </Grid>

          <Box maxWidth="550px" marginX="auto" display="flex" justifyContent="space-between">
            <Text fontSize="20px" lineHeight="30px">
              Length of commitment
            </Text>
            <Text fontSize="20px" lineHeight="30px">
              {commitmentLength} year
            </Text>
          </Box>

          <Box
            maxWidth="550px"
            marginX="auto"
            marginTop="24px"
            paddingRight="15px"
            paddingLeft="10px"
          >
            <Slider
              step={1}
              min={0}
              max={3}
              value={commitmentLength}
              marks
              onChange={(event, value) => {
                if (value > 0) {
                  GaEvents[GaEventCategory.CALCULATION_SCREEN].modifyDealLength();
                  setFormData({ commitmentLength: value });
                }
              }}
            />
          </Box>
        </>
      )}

      <Box
        maxWidth="550px"
        marginX="auto"
        paddingTop={{
          xs: '120px',
          sm: '160px',
          md: '200px',
        }}
      >
        <Text fontSize="18px" lineHeight="26px" textAlign="center">
          For many artists, the actual advance can be more than the estimate once our algorithm
          processes your official distribution reports in the next step.
        </Text>
      </Box>

      <Footer currentStepNumber={currentStepNumber} setCurrentStepNumber={setCurrentStepNumber}>
        <SubmitButton type="submit" onClick={handleSubmit} disabled={!(!loading && !loadingSubmit)}>
          {loadingSubmit ? (
            <Box minWidth="120px" paddingTop="7px">
              <CircularProgress sx={{ color: '#ddd' }} size="24px" />
            </Box>
          ) : (
            <Text fontSize="20px" fontWeight="bold" color="#fff" paddingX="24px" paddingY="9px">
              Next
            </Text>
          )}
        </SubmitButton>
      </Footer>

      <FaqSupportLink
        onClick={() => {
          GaEvents[GaEventCategory.CALCULATION_SCREEN].supportLink();
        }}
      />
      <ErrorModal
        open={openErrorModal}
        handleClose={() => setOpenErrorModal(false)}
        message={message}
      />
    </Box>
  );
}
