import { Container } from '@mui/material';

import ReviewInformation from './screens/ReviewInformation';
import SelectSongs from './screens/SelectSongs';
import CalculateDeal from './screens/CalculateDeal';
import UploadReport from './screens/UploadReport';
import DealSubmitted from './screens/deal-submitted';

interface IProps {
  store: any;
}

export default function DealFlow(props: IProps) {
  const { store } = props;
  const { state } = store;
  const { setCurrentStepNumber, setFormData } = store;
  const currentStepNumber = store.state.currentStepNumber;

  return (
    <Container maxWidth="md">
      {currentStepNumber === 1 && (
        <ReviewInformation
          state={state}
          setFormData={setFormData}
          setCurrentStepNumber={setCurrentStepNumber}
        />
      )}
      {currentStepNumber === 2 && <SelectSongs store={store} />}
      {currentStepNumber === 3 && <CalculateDeal store={store} />}
      {currentStepNumber === 4 && <UploadReport store={store} />}
      {currentStepNumber === 5 && <DealSubmitted />}
    </Container>
  );
}
