import { CSSProperties } from 'react';

export type DeclaredColors = {
  main: {
    black: CSSProperties['color'];
    blackLight: CSSProperties['color'];
    white: CSSProperties['color'];
    purple: CSSProperties['color'];
    purpleDark: CSSProperties['color'];
    yellow: CSSProperties['color'];
    yellowDark: CSSProperties['color'];
  };
  system: {
    error: CSSProperties['color'];
    warning: CSSProperties['color'];
    success: CSSProperties['color'];
    deal: CSSProperties['color'];
  };
  text: {
    light: {
      title: CSSProperties['color'];
      body: CSSProperties['color'];
      label: CSSProperties['color'];
      placeholder: CSSProperties['color'];
      disabled: CSSProperties['color'];
    };
    dark: {
      title: CSSProperties['color'];
      body: CSSProperties['color'];
      label: CSSProperties['color'];
      placeholder: CSSProperties['color'];
      disabled: CSSProperties['color'];
    };
  };
  background: {
    light: {
      b4: CSSProperties['color'];
      b3: CSSProperties['color'];
      b2: CSSProperties['color'];
      b1: CSSProperties['color'];
    };
    dark: {
      b4: CSSProperties['color'];
      b3: CSSProperties['color'];
      b2: CSSProperties['color'];
      b1: CSSProperties['color'];
      darkBlue: CSSProperties['color'];
    };
  };
};
export const colors = {
  main: {
    black: '#000000',
    blackLight: '#212121',
    white: '#ffffff',
    purple: '#7801ef',
    purpleDark: '#5800af',
    yellow: '#fedc00',
    yellowDark: '#e3c500',
  },
  system: {
    error: '#e22716',
    warning: '#ffa800',
    success: '#1acc81',
    deal: '#19b6fa',
  },
  text: {
    light: {
      title: '#222222',
      body: '#333333',
      label: '#484848',
      placeholder: '#818181',
      disabled: '#b7b7b7',
      heading: '#222222',
    },
    dark: {
      title: '#ffffff',
      body: '#d2d2d2',
      label: '#a1a1a1',
      placeholder: '#888888',
      disabled: '#3f3f3f',
      heading: '#DADADA',
    },
  },
  background: {
    light: {
      b4: '#dfe1e6',
      b3: '#ebecf0',
      b2: '#f4f5e7',
      b1: '#fafbfc',
    },
    dark: {
      b4: '#393c49',
      b3: '#363942',
      b2: '#2c3138',
      b1: '#1d1e21',
      darkBlue: '#151515',
      deepBlue: '#14161B',
    },
  },
};
