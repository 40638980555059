import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ErrorModal from 'components/error-modal';
// custom hooks
import { useUserData } from 'hooks/useUserData';
import { useError } from 'hooks/useError';
// api
import api from 'utils/api';
import LandingPage from './components/landing-page';
import { GaEvents, GaEventCategory } from 'utils/gaEvents';

const Homepage: React.FC = () => {
  const { message, setError } = useError();

  const [openErrorModal, setOpenErrorModal] = useState(false);

  const history = useHistory();

  const { userData, updateUserData } = useUserData();

  useEffect(() => {
    const queryString = history.location.search;

    if (queryString) {
      const searchParams = new URLSearchParams(history.location.search);
      const token = searchParams.get('token');

      const localToken = localStorage.getItem('Authorization');

      if (localToken && token) {
        history.replace('/');
      } else if (token) {
        const auth = async () => {
          try {
            await api.auth.confirmEmail({ data: { token } });
            const data = await api.auth.getProfile();
            GaEvents[GaEventCategory.ACCOUNT_PAGE].emailConfirmed();
            const completeUserData = { ...data, isAuthorized: true };
            updateUserData(completeUserData);
            history.push({ pathname: '/intro', state: { showIntro: true } });
          } catch (err) {
            setError(err);
            setOpenErrorModal(true);
          }
        };

        auth().catch((err) => {
          setError(err);
          setOpenErrorModal(true);
        });
      }
    }
  }, [history, setError, updateUserData]);

  return (
    <>
      <LandingPage isAuthorized={userData.isAuthorized} />
      <ErrorModal
        open={openErrorModal}
        handleClose={() => setOpenErrorModal(false)}
        message={message}
      />
    </>
  );
};

export default Homepage;
