import React from 'react';

import { Box } from '@mui/material';
import { BoxProps, keyframes } from '@mui/system';

interface IRenderAnimationFunc {
  animation: string;
  suffix: string;
  delay: string;
  visible: boolean;
}

const renderAnimation = ({ animation, suffix, delay, visible }: IRenderAnimationFunc): object => {
  if (visible) {
    return {
      animation: `${keyframes(`${animation}`)} ${suffix}`,
      animationDelay: delay,
    };
  }

  return {};
};

interface ISlideUpAnimation extends BoxProps {
  distance?: string;
  delay?: string;
  duration?: string;
  visible?: boolean;
  animateOpacity?: boolean;
  mode?: 'forwards' | 'backwards' | 'both';
  animation?: 'ease-out' | 'ease-in' | 'ease-in-out' | 'ease';
  children?: React.ReactNode;
}

const SlideUpAnimation: React.FC<ISlideUpAnimation> = ({
  sx = {},
  children,
  delay = '0ms',
  mode = 'forwards',
  animation = 'ease',
  distance = '30px',
  duration = '500ms',
  visible = true,
  animateOpacity = true,
  ...rest
}) => {
  return (
    <Box
      sx={{
        opacity: animateOpacity ? 0 : 1,
        ...renderAnimation({
          animation: `
            from { 
              transform: translateY(${distance});
              ${animateOpacity ? 'opacity: 0;' : ''}
            } to { 
              transform: translate(0px);
              ${animateOpacity ? 'opacity: 1;' : ''}
            }
          `,
          suffix: `${duration} ${mode} ${animation}`,
          delay,
          visible,
        }),
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default SlideUpAnimation;
