import React, { useEffect, useMemo, useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
// mui components
import { CircularProgress, Grid } from '@mui/material';
// components
import Header from 'components/header';
// custom hooks
import { useUserData } from 'hooks/useUserData';
// api
import api from 'utils/api';
// routes
import { authorizedRoutes, unauthorizedRoutes } from './routes';
// styles
import { colors } from 'styles/colors';

function App() {
  const location = useLocation();
  const [hideHeader, setHideHeader] = useState(true);
  const { userData, updateUserData } = useUserData();

  useEffect(() => {
    const accessToken = localStorage.getItem('Authorization');

    const fetchUserData = async () => {
      try {
        const data = await api.auth.getProfile();
        const completedData = { ...data, isAuthorized: true };
        updateUserData(completedData);
      } catch (err) {
        console.log(err);
      }
    };

    if (accessToken) {
      fetchUserData().catch((err) => console.error(err));
    } else {
      updateUserData({ isAuthorized: false });
    }
  }, [updateUserData]);

  useEffect(() => {
    setHideHeader(window.location.pathname === '/');
  }, [location.pathname]);

  const currentRoutes = useMemo(
    () => (userData.isAuthorized ? authorizedRoutes : unauthorizedRoutes),
    [userData]
  );

  if (userData.isAuthorized === null) {
    return (
      <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
        <CircularProgress size={70} sx={{ color: colors.main.purple }} />
      </Grid>
    );
  }

  return (
    <>
      {!hideHeader ? <Header /> : null}
      <Switch>
        {currentRoutes.map(({ path, component, exact = false }) => (
          <Route key={path} path={path} exact={exact} component={component} />
        ))}
        <Redirect to="/" />
      </Switch>
    </>
  );
}

export default App;
