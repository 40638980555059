import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';

const PurpleButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: theme.colors.main.purple,
  ...theme.fontStyles.body,
  color: theme.colors.main.white,
  textTransform: 'unset',
  height: '48px',
  '&:hover': {
    backgroundColor: theme.colors.main.purpleDark,
  },
})) as typeof LoadingButton;

export default PurpleButton;
