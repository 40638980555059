import React, { useState, useRef } from 'react';

import { Box, Container, Grid } from '@mui/material';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import fineartTextXlIcon from 'assets/icons/fineart_text_xl.svg';
import { keyframes } from '@mui/system';
import { colors } from 'styles/colors';
import { useWidth } from 'hooks/useWidth';
import useOnScreen from 'hooks/useOnScreen';
import Text from 'components/text';

import cardImage1 from 'assets/images/landing-card-1.jpeg';
import cardImage2 from 'assets/images/landing-card-2.jpeg';
import cardImage3 from 'assets/images/landing-card-3.jpeg';
import cardImage4 from 'assets/images/landing-card-4.jpeg';
import useInterval from 'hooks/useInterval';

interface BoxCardProps {
  label: string;
  description: string;
  highlight?: boolean;
}

const BoxCard: React.FC<BoxCardProps> = (props) => {
  const sx = props.highlight
    ? {
        minHeight: '220px',
      }
    : {
        minHeight: '180px',
      };
  return (
    <Box
      bgcolor={props.highlight ? colors.main.purpleDark : colors.main.black}
      padding="24px"
      sx={sx}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Text
        lineHeight="1.5em"
        fontSize="32px"
        fontWeight={600}
        color="#DEDEDE"
        marginBottom="12px"
        textAlign="center"
      >
        {props.label}
      </Text>
      <Text lineHeight="1.5em" fontSize="20px" color="#9FA5AE" textAlign="center">
        {props.description}
      </Text>
    </Box>
  );
};

const ImageSlideShow = () => {
  const [images, setImages] = useState([cardImage1, cardImage2, cardImage3, cardImage4]);

  useInterval(() => {
    const newImages = [...images];
    const firstImage = newImages.pop();
    if (firstImage) {
      newImages.unshift(firstImage);
    }
    setImages(newImages);
  }, 5000);

  return (
    <Box
      marginTop={{
        xs: '50px',
        md: '60px',
      }}
      marginBottom="30px"
      position="relative"
      width="100%"
      zIndex={1}
      overflow="hidden"
      height={{
        xs: '200px',
        md: '250px',
        lg: '300px',
      }}
    >
      {images.map((image, index) => {
        return (
          <Box
            key={index}
            component="img"
            src={image}
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            alt="Image showing encouragement to support independent artists"
            sx={{
              width: '100%',
              height: '300px',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        );
      })}
    </Box>
  );
};

interface ResponsiveTextBlockProps {
  children: React.ReactNode;
  beniRegular?: boolean;
  color: string;
}

const ResponsiveTextBlock: React.FC<ResponsiveTextBlockProps> = (props) => {
  const { children, beniRegular = false, color } = props;
  return (
    <Text
      component="span"
      display={{
        xs: 'block',
        md: 'inline',
      }}
      color={color}
      beniRegular={beniRegular}
    >
      {children}
    </Text>
  );
};

const renderAnimation = (
  animation: string,
  prefix: string,
  delay: number,
  visible: boolean
): object => {
  if (visible) {
    return {
      animation: `${keyframes(`${animation}`)} ${prefix}`,
      animationDelay: `${delay}ms`,
    };
  }

  return {};
};

export default function AboutUs() {
  const ref = useRef(null);
  const { isMobile } = useWidth();
  const isVisible = useOnScreen(ref, isMobile ? '-50px' : '-200px');

  return (
    <Grid container flexDirection="column">
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 2 }} ref={ref}>
        <Grid
          container
          paddingTop={{
            xs: '80px',
            md: '150px',
          }}
        >
          <Grid item xs={12} md={7}>
            <Box
              textAlign={{
                xs: 'center',
                md: 'right',
              }}
              position="relative"
            >
              <Box
                height={{
                  xs: '215px',
                  md: '120px',
                  lg: '138px',
                }}
                position="relative"
                overflow="hidden"
              >
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  sx={{
                    transform: 'translateX(100%)',
                    ...renderAnimation(
                      `from {
                        transform: translateY(100%);
                      }
                      to {
                        transform: translateY(0px);
                      }`,
                      '1s forwards ease-out',
                      300,
                      isVisible
                    ),
                  }}
                >
                  <Text
                    color={colors.text.dark.title}
                    fontSize={{
                      xs: '64px',
                      md: '54px',
                      lg: '64px',
                    }}
                    lineHeight="1em"
                    beniRegular
                    letterSpacing="1.2px"
                  >
                    <ResponsiveTextBlock color="#DEDEDE" beniRegular>
                      Are We a record label?
                    </ResponsiveTextBlock>{' '}
                    <ResponsiveTextBlock color="#DEDEDE" beniRegular>
                      A distributor?
                    </ResponsiveTextBlock>
                  </Text>

                  <Text
                    color={colors.text.dark.title}
                    fontSize={{
                      xs: '64px',
                      md: '54px',
                      lg: '64px',
                    }}
                    lineHeight="1em"
                    letterSpacing="1.2px"
                    beniRegular
                    marginTop={{
                      xs: '25px',
                      md: '10px',
                    }}
                    sx={{
                      transform: {
                        md: 'translateX(-20px)',
                      },
                    }}
                  >
                    Neither. Either.{' '}
                    <Box component={'span'} color={colors.main.yellow}>
                      Both {':)'}
                    </Box>
                  </Text>
                </Box>
              </Box>

              <Box
                height="25px"
                width="50%"
                bgcolor={colors.main.purpleDark}
                position="absolute"
                right={0}
                bottom={0}
                zIndex={-1}
                maxWidth="160px"
                marginRight={{
                  xs: '15%',
                  sm: '30%',
                  md: '0px',
                }}
                sx={{
                  ...renderAnimation(
                    `from {
                      opacity: 0;
                      transform: translateY(0px);
                    }
                    to {
                      opacity: 1;
                      transform: translateY(5px);
                    }`,
                    '1s forwards ease-out',
                    1000,
                    isVisible
                  ),
                  opacity: 0,
                }}
              />
            </Box>

            <Box
              sx={{
                ...renderAnimation(
                  `from {
                    opacity: 0;
                    transform: translateY(100px);
                  }
                  to {
                    opacity: 1;
                    transform: translateY(0px);
                  }`,
                  '1s forwards ease-out',
                  800,
                  isVisible
                ),
                opacity: 0,
              }}
            >
              <ImageSlideShow />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={5}
            paddingLeft={{ xs: '0px', md: '50px' }}
            sx={{
              ...renderAnimation(
                `from {
                    opacity: 0;
                    transform: translateY(80px);
                  }
                  to {
                    opacity: 1;
                    transform: translateY(0px);
                  }`,
                '1s forwards ease-out',
                700,
                isVisible
              ),
              opacity: 0,
            }}
          >
            <Box>
              <Text
                color="#9FA5AE"
                fontSize={{
                  xs: '22px',
                  md: '25px',
                  lg: '32px',
                }}
                lineHeight="1.35em"
                textAlign={{
                  xs: 'center',
                  md: 'left',
                }}
              >
                Our roots are as a record label. We started signing and working with artists almost
                five years ago.
              </Text>
              <Text
                color="#9FA5AE"
                fontSize={{
                  xs: '22px',
                  md: '25px',
                  lg: '32px',
                }}
                lineHeight="1.35em"
                paddingTop="20px"
                textAlign={{
                  xs: 'center',
                  md: 'left',
                }}
              >
                Since then, we've expanded into{' '}
                <Text color="#ffffff" component="span" fontWeight="bold">
                  distribution and artist financing
                </Text>{' '}
                to meet the needs of the growing class of independent artists.
              </Text>
            </Box>
          </Grid>
        </Grid>

        <Box
          marginTop={{
            xs: '75px',
            md: '90px',
            lg: '100px',
          }}
          paddingBottom="30px"
        >
          <Text
            color="#9FA5AE"
            fontSize={{
              xs: '28px',
              md: '32px',
            }}
            textAlign="center"
            lineHeight="1.35em"
          >
            <ResponsiveTextBlock color="#9FA5AE">Visual learner? Us too.</ResponsiveTextBlock>{' '}
            <Box
              component="span"
              display={{
                xs: 'block',
                md: 'inline',
              }}
              fontSize={{
                xs: '26px',
                md: '32px',
              }}
            >
              Here's how{' '}
              <Text
                component="span"
                fontWeight="bold"
                color="#FEDC00"
                fontSize={{
                  xs: '26px',
                  md: '32px',
                }}
              >
                we visualize our company
              </Text>
            </Box>
          </Text>

          <Box
            marginY="40px"
            textAlign="center"
            sx={{
              '& path': {
                fill: colors.main.white,
              },
            }}
          >
            <Logo />
          </Box>
        </Box>

        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={4}>
            <BoxCard
              label="Distro"
              highlight={false}
              description="Our platform for tying it all together. A place for artists to push their music and
              manage their careers."
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <BoxCard
              label="FineArt"
              highlight={true}
              description="Funding independence. Giving advances on artists back catalogs"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <BoxCard
              label="Label / growth"
              highlight={false}
              description="Working with a handful of artists on new music, often those who started with FineArt or Distro"
            />
          </Grid>
        </Grid>
      </Container>

      <Box position="relative" zIndex={1}>
        <Box
          sx={{
            ...renderAnimation(
              `from {
                    opacity: 0;
                    transform: translateY(200px);
                  }
                  to {
                    opacity: 1;
                    transform: translateY(0px);
                  }`,
              '1s forwards ease-out',
              1200,
              isVisible
            ),
            opacity: 0,
          }}
        >
          <Box
            component="img"
            src={fineartTextXlIcon}
            position="absolute"
            bottom={0}
            left={0}
            width="80%"
            sx={{
              transform: {
                xs: 'translateY(-1150px)',
                sm: 'translateY(-1100px)',
                md: 'translateY(-400px)',
                lg: 'translateY(-350px)',
              },
            }}
          />
        </Box>
      </Box>
    </Grid>
  );
}
