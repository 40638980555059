import { useRef } from 'react';
import Text from 'components/text';
import { colors } from 'styles/colors';
import { Link } from 'react-router-dom';
import ctaSvg from 'assets/icons/cta.svg';
import { useWidth } from 'hooks/useWidth';
import useOnScreen from 'hooks/useOnScreen';
import { Box, Container } from '@mui/material';
import BarAnimator from 'components/bar-animation';
import CutOutAnimator from 'components/cut-out-animation';
import SlideUpAnimation from 'components/slideup-animation';
import backgroundImage from 'assets/images/landing-background.jpg';
import { GaEvents, GaEventCategory } from 'utils/gaEvents';

export default function Intro(props: any) {
  const { isAuthorized } = props;
  const ref = useRef(null);
  const { isMobile } = useWidth();
  const isVisible = useOnScreen(ref, isMobile ? '-50px' : '-100px');

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      paddingBottom="20px"
      ref={ref}
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100vw',
        minHeight: '100vh',
        marginTop: '-80px',
      }}
    >
      <Container maxWidth="xl" sx={{ paddingTop: { lg: '160px', xs: '100px' } }}>
        <CutOutAnimator
          textAlign={{ xs: 'center', sm: 'left' }}
          height={{ xs: '38px', sm: '45px', md: '55px', lg: '65px', xl: '68px' }}
          marginLeft={{ xs: '0%', sm: '10%', md: '13%', lg: '15%', xl: '16%' }}
        >
          <CutOutAnimator.AnimatedItem delay="10ms" duration="1s" visible={isVisible}>
            <Text
              beniRegular
              fontSize={{ xs: '50px', sm: '60px', md: '75px', lg: '90px', xl: '96px' }}
              fontWeight={300}
              color={colors.text.dark.heading}
              lineHeight={0.65}
              letterSpacing={2}
            >
              Get an advance within days
            </Text>
          </CutOutAnimator.AnimatedItem>
        </CutOutAnimator>

        <BarAnimator
          zIndex={1}
          marginTop={{
            xs: '15px',
            sm: '25px',
            md: '35px',
          }}
          marginLeft={{ xs: '0%', sm: '30%', md: '38%', lg: '42%', xl: '45%' }}
          height={{ xs: '36px', sm: '42px', md: '54px', lg: '64px', xl: '68px' }}
        >
          <CutOutAnimator
            height={{
              xs: '38px',
              sm: '45px',
              md: '55px',
              lg: '65px',
            }}
            zIndex={2}
            textAlign={{ xs: 'center', sm: 'left' }}
          >
            <CutOutAnimator.AnimatedItem delay="900ms" duration="1s" visible={isVisible}>
              <Text
                beniRegular
                fontSize={{ xs: '50px', sm: '60px', md: '75px', lg: '90px', xl: '96px' }}
                fontWeight={300}
                color={colors.text.dark.heading}
                lineHeight={0.65}
                letterSpacing={2}
              >
                Keep{' '}
                <Box component="span" color={colors.main.yellow}>
                  100%
                </Box>{' '}
                of your rights
              </Text>
            </CutOutAnimator.AnimatedItem>
          </CutOutAnimator>
          <BarAnimator.Bar
            id="thebar"
            visible={isVisible}
            from="width: 0%;"
            to="width: 54%;"
            height="24px"
            width="0%"
            bottom={-8}
            delay="300ms"
            duration="1s"
            left={{
              xs: '5%',
              sm: -25,
            }}
            bgcolor={colors.main.purple}
          />
        </BarAnimator>
      </Container>

      <Box
        marginTop={{
          xs: '160px',
          sm: '150px',
          md: '80px',
        }}
      >
        <SlideUpAnimation delay="1s" duration="1s" visible={isVisible}>
          <Text
            textAlign="center"
            color={colors.text.dark.heading}
            fontSize={{
              xs: '20px',
              sm: '24px',
              md: '28px',
            }}
            lineHeight={{
              xs: '25px',
              sm: '29px',
              md: '33px',
            }}
          >
            Your catalog is more valuable than ever.
            <br />
            See how much your songs are worth.
          </Text>
        </SlideUpAnimation>
      </Box>

      <SlideUpAnimation delay="1.5s" duration="1s" distance="20px" visible={isVisible}>
        <Box
          marginTop={{
            xs: '75px',
            sm: '70px',
            md: '60px',
          }}
          textAlign="center"
        >
          <Box
            component={Link}
            bgcolor="transparent"
            border="none"
            display="inline-flex"
            justifyContent="center"
            to={isAuthorized ? '/profile/deals' : '/sign-up'}
            sx={{
              textDecoration: 'none',
            }}
            onClick={() => {
              GaEvents[GaEventCategory.LANDING_PAGE].clickedStartToday();
            }}
          >
            <Text textAlign="center" color={colors.text.dark.heading} fontSize="26px">
              Start today
            </Text>
            <Box
              paddingBottom={2.5}
              component={'img'}
              src={ctaSvg}
              marginLeft="16px"
              sx={{
                width: '34px',
                cursor: 'pointer',
                '& path': {
                  fill: colors.main.white,
                },
              }}
            />
          </Box>
        </Box>
      </SlideUpAnimation>
    </Box>
  );
}
