import React from 'react';
// mui component
import { Grid, Typography } from '@mui/material';
// components
import ChangedInput from '../../components/changed-input';
// custom hooks
import { useUserData } from 'hooks/useUserData';
// styles
import { fontStyles } from 'styles/font-styles';
import { colors } from 'styles/colors';
import Text from 'components/text';
import ChangedPasswordInput from 'pages/profile/components/changed-password-input';

const Profile: React.FC = () => {
  const { userData } = useUserData();

  return (
    <Grid
      container
      flexDirection="column"
      sx={{ maxWidth: '500px', margin: { xs: '16px', md: '40px' } }}
    >
      <Typography sx={{ margin: '14px 0', ...fontStyles.h3, color: colors.text.light.title }}>
        Hello{userData.fullName ? `, ${userData.fullName}` : ''}
      </Typography>

      <Text fontSize="24px" marginTop="24px">
        Profile
      </Text>
      <ChangedInput name="fullName" label="Full name" />
      {/* <ChangedInput name="address" label="Address" />
      <ChangedInput name="EPK_link" label="EPK link" /> */}

      <Text fontSize="24px" marginTop="44px">
        Settings
      </Text>
      <ChangedInput name="email" label="Email" type="email" disabledEdit={true} />
      <ChangedPasswordInput />
    </Grid>
  );
};

export default Profile;
